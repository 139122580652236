import {combineReducers} from "redux"
import changeCategoryReducer from "./changeCategoryReducer"
import changeProductReducer from "./changeProductReducer"
import categoryListReducer from "./categoryListReducer"
import productListReducer from "./productListReducer"
import cartReducer from "./cartReducer"
import saveProductReducer from "./saveProductReducer"
import { alertReducer } from "./alertReducer"

const rootReducer = combineReducers({
    changeCategoryReducer,
    categoryListReducer,
    productListReducer,
    cartReducer,
    saveProductReducer,
    changeProductReducer,
    alert: alertReducer,
})

export default rootReducer;