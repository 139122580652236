import * as actionTypes from "./actionTypes";

export function changeCategory(category) {
  return { type: actionTypes.CHANGE_CATEGORY, payload: category };
}

export function getCategoriesSuccess(categories) {
  return { type: actionTypes.GET_CATEGORIES_SUCCESS, payload: categories }
}

export function getCategories() {
  return function(dispatch) {
      //let url = "http://localhost:80/php-backend/action.php/";
      let url = 'https://www.alazmuhendislik.com/php-backend/categories.php/';
      // let formaction = 'categories';
      // const formData = new FormData();
        // formData.append('action', formaction);
        fetch(url, {
          method: 'POST',
          // body: formData
        })
        .then(response => response.json())
        .then(result => dispatch(getCategoriesSuccess(result)));
  };
}

