import React from "react";
import Owldemo1 from "../../Owldemo1";
import CarouselWrite from "../carousel/CarouselWrite";
import Slider from "../carousel/Slider";
import Popular from "../products/Popular";
import Videos from "../work/Videos";
import Works from "../work/Works";
import Works1 from "../work/Works1";

const Dashboard = () => {
  window.scrollTo(0, 0);
   
  return (
    
    <div >
      <div >
        <Slider />
      </div>
      <br />
      <div>
        <Works />
      </div>
      <div>
        <Works1 />
      </div>
      <br />
      <div>
        <CarouselWrite />
      </div>
      <br />

      <div style={{
                    backgroundImage: "url(" + "https://www.alazmuhendislik.com/resimler/arkpln.webp" + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100vh',
                   
                  }}>
        <Owldemo1 />
      </div>
      <br />
      <div>
        <br />
        <Popular />
      </div>
     <div>
        <Videos />
      </div>   
     
    </div>
  );
};
export default Dashboard;
