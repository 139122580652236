import React, { useState, useEffect } from "react";
//import { Link, useNavigate } from "react-router-dom";   // react-router-dom  --> Versiyon 6 sonrası geçerli
import { Table, Button, Row, Col } from "reactstrap";
import { useHistory,Link } from "react-router-dom";
import alertify from "alertifyjs";

function Order() {
  const [siparisler, setSiparisler] = useState([]);
  // const navigate = useNavigate();    // react-router-dom  --> Versiyon 6 sonrası geçerli
  let history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      // navigate("/");   // react-router-dom  --> Versiyon 6 sonrası geçerli
      history.push("/login");
    } else {
      let url = "https://www.alazmuhendislik.com/php-backend/users.php/";
      const formData = new FormData();
      formData.append("kullanicilar_id", localStorage.getItem("user-info"));
      formData.append("siparislerim", 1);
      fetch(url, {
        method: "POST",
        body: formData,
      })
        //  .then(response => response.text())
        //  .then(result => console.log(result));
        .then((response) => response.json())
        .then((result) => {
          // alertify.success(JSON.stringify(result),5);
          // alertify.success(result[0].kullanicilar_id,5);
          setSiparisler(result);
        });
    }
  }, []);
  if (window.innerWidth > 700) {
    return (
      <div>
        <h3 style={{ fontSize: "1,5vw", textAlign: "center" }}>
          SİPARİŞLERİM
        </h3>
        <br />

        <Table style={{ width: "1500px" }}>
          <thead>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>
              SİPARİŞ NUMARASI
            </th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>TARİH</th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>DURUM</th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>ÜRÜN ADI</th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>ADEDİ</th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>
              BİRİM FİYAT
            </th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>
              TOPLAM TUTAR
            </th>
          </thead>
          <tbody>
            {siparisler.map((siparis) => (
              <tr key={siparis.siparisler_id}>
                <td style={{ fontSize: "1.2vw", textAlign: "center",verticalAlign: 'middle',}}>
                <Link to={"/siparislerimDetay/" + siparis.siparisler_id}>
                  {siparis.paytr_id}
                </Link>
                </td>
                <td style={{ fontSize: "1.2vw", textAlign: "center",verticalAlign: 'middle', }}>
                  {siparis.siparisler_tarih}
                </td>
                <td style={{ fontSize: "1.2vw", textAlign: "center",verticalAlign: 'middle', }}>
                  {siparis.siparisler_durum == 1 ? "Sipariş Alındı" : "Ödeme Başarısız"}
                </td>
                <td style={{ fontSize: "1.2vw", textAlign: "center",verticalAlign: 'middle', }}>
                  {siparis.siparisler_urunadi
                    .split(",")
                    .map((altSatir, index) => (
                      altSatir?
                      <span key={index}>
                        {altSatir}
                        <hr />
                      </span>
                      :null
                    ))}
                </td>
                <td style={{ fontSize: "1.2vw", textAlign: "center",verticalAlign: 'middle', }}>
                  {siparis.siparisler_urunadet
                    .split(",")
                    .map((altSatir, index) => (
                      altSatir?
                      <span key={index}>
                        {altSatir} Adet
                        <hr />
                      </span>
                      :null
                    ))}
                </td>
                <td style={{ fontSize: "1.2vw", textAlign: "center",verticalAlign: 'middle', }}>
                  {siparis.siparisler_aldigifiyat
                    .split(",")
                    .map((altSatir, index) => (
                      altSatir?
                      <span key={index}>
                        {altSatir} TL
                        <hr />
                      </span>
                      :null
                    ))}
                </td>
                <td style={{ fontSize: "1.2vw", textAlign: "center",verticalAlign: 'middle', }}>
                  {siparis.siparisler_toplam} TL
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  } else if (window.innerWidth < 700) {
    return (
      <div>
        <h3 style={{ fontSize: "1,5vw", textAlign: "center" }}>SİPARİŞLERİM</h3>
        <br />
        <Table>
          <thead>
            <th style={{ fontSize: "2vw", textAlign: "center" }}>ÜRÜN ADI</th>
            <th style={{ fontSize: "2vw", textAlign: "center" }}>ADEDİ</th>
            <th style={{ fontSize: "2vw", textAlign: "center" }}>
              TOPLAM TUTAR
            </th>
          </thead>
          <tbody>
            {siparisler.map((siparis) => (
              <tr key={siparis.siparisler_id}>
                <td style={{ fontSize: "1.2vw", textAlign: "center",verticalAlign: 'middle',}}>
                <Link to={"/siparislerimDetay/" + siparis.siparisler_id}>
                  {siparis.paytr_id}
                </Link>
                </td>
                <td style={{ fontSize: "1.4vw", textAlign: "center",verticalAlign: 'middle',}}>
                  {siparis.siparisler_urunadi
                    .split(",")
                    .map((altSatir, index) => (
                      altSatir?
                      <span key={index}>
                        {altSatir}
                        <hr />
                      </span>
                      :null
                    ))}
                </td>
                <td style={{ fontSize: "1.4vw", textAlign: "center",verticalAlign: 'middle', }}>
                  {siparis.siparisler_urunadet
                    .split(",")
                    .map((altSatir, index) => (
                      altSatir?
                      <span key={index}>
                        {altSatir} Adet
                        <hr />
                      </span>
                      :null
                    ))}
                </td>
                <td style={{ fontSize: "1.4vw", textAlign: "center",verticalAlign: 'middle', }}>
                  {siparis.siparisler_toplam} TL
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
export default Order;
