import React, { Component } from "react";
import { Alert } from "reactstrap";

export default class Iade extends Component {
  render() {
    window.scrollTo(0,0);
    return (
      <div>
        <Alert>
          <h2 className="alert-heading">İPTAL VE İADE KOŞULLARI</h2>
          <hr />
          <h5 className="alert-heading">GENEL :</h5>
          <p>
            Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda
            sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu
            ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız. Alıcılar,
            satın aldıkları ürünün satış ve teslimi ile ilgili olarak 6502
            sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler
            Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer
            yasalara tabidir. Ürün sevkiyat masrafı olan kargo ücretleri
            alıcılar tarafından ödenecektir. Satın alınan her bir ürün, 30
            günlük yasal süreyi aşmamak kaydı ile alıcının gösterdiği adresteki
            kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürün teslim
            edilmez ise, Alıcılar sözleşmeyi sona erdirebilir. Satın alınan
            ürün, eksiksiz ve siparişte belirtilen niteliklere uygun ve varsa
            garanti belgesi, kullanım kılavuzu gibi belgelerle teslim edilmek
            zorundadır. Satın alınan ürünün satılmasının imkansızlaşması
            durumunda, satıcı bu durumu öğrendiğinden itibaren 3 gün içinde
            yazılı olarak alıcıya bu durumu bildirmek zorundadır. 14 gün içinde
            de toplam bedel Alıcı’ya iade edilmek zorundadır.
          </p>
          <hr />
          <h5 className="alert-heading">
            SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:{" "}
          </h5>
          <p>
            Alıcı, satın aldığı ürün bedelini ödemez veya banka kayıtlarında
            iptal ederse, Satıcının ürünü teslim yükümlülüğü sona erer.
          </p>
          <hr />

          <h5 className="alert-heading">
            KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:
          </h5>
          <p>
            Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi kartının
            yetkisiz kişiler tarafından haksız olarak kullanıldığı tespit
            edilirse ve satılan ürün bedeli ilgili banka veya finans kuruluşu
            tarafından Satıcı'ya ödenmez ise, Alıcı, sözleşme konusu ürünü 3 gün
            içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya
            iade etmek zorundadır.
          </p>
          <hr />

          <h5 className="alert-heading">
            ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE:
          </h5>
          <p>
            Satıcı’nın öngöremeyeceği mücbir sebepler oluşursa ve ürün süresinde
            teslim edilemez ise, durum Alıcı’ya bildirilir. Alıcı, siparişin
            iptalini, ürünün benzeri ile değiştirilmesini veya engel ortadan
            kalkana dek teslimatın ertelenmesini talep edebilir. Alıcı siparişi
            iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren 14
            gün içinde kendisine nakden bu ücret ödenir. Alıcı, ödemeyi kredi
            kartı ile yapmış ise ve iptal ederse, bu iptalden itibaren yine 14
            gün içinde ürün bedeli bankaya iade edilir, ancak bankanın alıcının
            hesabına 2-3 hafta içerisinde aktarması olasıdır.
          </p>
          <hr />

          <h5 className="alert-heading">
            ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:
          </h5>
          <p>
            Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene
            edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı
            mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan
            mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. ALICI ,
            Teslimden sonra mal/hizmeti özenle korunmak zorundadır. Cayma hakkı
            kullanılacaksa mal/hizmet kullanılmamalıdır. Ürünle birlikte Fatura
            da iade edilmelidir.
          </p>
          <hr />

          <h5 className="alert-heading">CAYMA HAKKI: </h5>
          <p>
            ALICI satın aldığı ürünün kendisine veya gösterdiği adresteki
            kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün
            içerisinde, SATICI’ya aşağıdaki iletişim bilgileri üzerinden
            bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin
            ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma
            hakkını kullanabilir.
          </p>
          <hr />

          <h5 className="alert-heading">
            SATICININ CAYMA HAKKI BİLDİRİMİ YAPILACAK İLETİŞİM BİLGİLERİ:{" "}
          </h5>
          <p>
            <strong>Alaz Mühendislik</strong>{" "}
          </p>
          <p>
            Adres: Gültepe Mahallesi Çatalan Caddesi No:129E İşyeri No:1 <br />
            Sarıçam / Adana / Türkiye
            <br />
            Telefon: +90 553 287 62 75
            <br />
            E-posta: info@alazmuhendislik.com
          </p>
          <hr />

          <h5 className="alert-heading">CAYMA HAKKININ SÜRESİ: </h5>
          <p>
            Alıcı, satın aldığı eğer bir hizmet ise, bu 14 günlük süre
            sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi
            sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan
            hizmet sözleşmelerinde cayma hakkı kullanılamaz. Cayma hakkının
            kullanımından kaynaklanan masraflar SATICI’ ya aittir. Cayma
            hakkının kullanılması için 14 (ondört) günlük süre içinde SATICI' ya
            iadeli taahhütlü posta, faks veya eposta ile yazılı bildirimde
            bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma Hakkı
            Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış
            olması şarttır.
          </p>
          <hr />

          <h5 className="alert-heading"> CAYMA HAKKININ KULLANIMI: </h5>
          <p>
            3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade
            edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun
            düzenlemiş olduğu iade faturası ile birlikte gönderilmesi
            gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri
            İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.) İade formu,
            İade edilecek ürünlerin kutusu, ambalajı, varsa standart
            aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim
            edilmesi gerekmektedir.
          </p>
          <hr />

          <h5 className="alert-heading"> İADE KOŞULLARI: </h5>
          <p>
            SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10
            günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına sokan
            belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı
            iade almakla yükümlüdür. ALICI’ nın kusurundan kaynaklanan bir
            nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa
            ALICI kusuru oranında SATICI’ nın zararlarını tazmin etmekle
            yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün
            usulüne uygun kullanılması sebebiyle meydana gelen değişiklik ve
            bozulmalardan ALICI sorumlu değildir. Cayma hakkının kullanılması
            nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının
            altına düşülmesi halinde kampanya kapsamında faydalanılan indirim
            miktarı iptal edilir.
          </p>
          <hr />

          <h5 className="alert-heading">
            {" "}
            TEMERRÜT HALİ VE HUKUKİ SONUÇLARI:{" "}
          </h5>
          <p>
            ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde
            düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı
            sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu
            olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka
            hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini
            ALICI’dan talep edebilir ve her koşulda ALICI’nın borcundan dolayı
            temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı
            SATICI’nın uğradığı zarar ve ziyanını ödeyeceğini kabul eder.
          </p>
          <hr />

          <h5 className="alert-heading"> ÖDEME VE TESLİMAT: : </h5>
          <p>
            Banka Havalesi veya EFT (Elektronik Fon Transferi) yaparak,
            KUVEYTTURK veya YAPIKREDİ banka hesaplarımızdan (TL) herhangi birine
            yapabilirsiniz. Sitemiz üzerinden kredi kartlarınız ile, Her türlü
            kredi kartınıza online tek ödeme ya da online taksit imkânlarından
            yararlanabilirsiniz. Online ödemelerinizde siparişiniz sonunda kredi
            kartınızdan tutar çekim işlemi gerçekleşecektir.
          </p>
          <hr />
        </Alert>
      </div>
    );
  }
}


