import React, { Component } from "react";
import {  UncontrolledCarousel } from "reactstrap";
import Carousellink from "./Carousellink";

export default class Slider extends Component {
  render() {
    if (window.innerWidth > 700) {
      return (
        <div>
         <Carousellink/>
        </div>
      );
    }
    else if (window.innerWidth < 700) {
      return (
        <UncontrolledCarousel
          items={[

            {
              key: 1,
              src: "https://www.alazmuhendislik.com/resimler/mobilges.png",
            }
            ,
             {
               key: 2,
              src: "https://www.alazmuhendislik.com/resimler/mobil5.webp",
              baglanti: "https://play.google.com/store/apps/details?id=com.alazkontroltarr"
            },
            {
              key: 3,
              src: "https://www.alazmuhendislik.com/resimler/mslider.webp",
            },
            {
              key: 4,
              src: "https://www.alazmuhendislik.com/resimler/mobil2.webp",
            },
            {
              key: 5,
              src: "https://www.alazmuhendislik.com/resimler/mslider1.webp",  
              baglanti: "https://play.google.com/store/apps/details?id=com.alazkontroltarr"         
            },
            {
              key: 6,
              src: "https://www.alazmuhendislik.com/resimler/ms_sc.png", 
              baglanti: "https://www.alazmuhendislik.com/categoryChange/3  "  
                   
            }
          
          ]}
        />
      );
    }
  }
}
 // Resim kodu responsive
//             <picture>
//       <source media="(min-width: 1024px)" src="image_Desktop.png"/>
//       <source media="(max-width: 768px)" src="image_Mobile.png"/>
//       <source media="(min-width: 768px) and (max-width: 1024px)" src="image_Tab.png"/>
//       <img src="image.png" alt="" styleName='brain-image'/>
//  </picture>
