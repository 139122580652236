import { color } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import {
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText
} from "reactstrap";


export default class CarouselWrite extends React.Component {
    render() {
        window.scrollTo(0, 0);
            return (
                <div>
                        <ListGroup flush style={{
                            height: '30vh',
                            textAlign: 'center',
                            // color:"#ff6600"

                        }}>
                            <ListGroupItem >
                            <br/> <br/>
                                <ListGroupItemHeading >
                                    HİZMETLERİMİZ
                                </ListGroupItemHeading>
                                <br />
                                <ListGroupItemText>
                                    Müşterilerimizin kullanımına sunulan anahtar teslim GES, Tarımsal Sulama ve Bağevi Paketlerimizle ülke geneline temiz enerji taşıyoruz.
                                </ListGroupItemText>
                            </ListGroupItem>
                        </ListGroup>
                       
                    </div>


            );
        

    }
}

