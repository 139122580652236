import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { GiPositionMarker } from "react-icons/gi";
import {Col, Alert} from "reactstrap";
import { FcAssistant, FcSms, FcFactory } from "react-icons/fc";

const AnyReactComponent = ({ text }) => (
  <div style={{ color: "#ff6600" }}>
    {<GiPositionMarker style={{ height: "50", width: "50" }} />}
    {text}
  </div>
);

export default class IletisimDetail extends Component {
  render() {
    window.scrollTo(0, 0);
    const defaultProps = {
      center: {
        lat: 37.0376375,
        lng: 35.3941036,
      },
      zoom: 18,
    };

    return (
      <div>
        <div style={{ height: "80vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyB8x1rAur-ex2nMQLM3v5EVvGKN8-jIP60",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={37.0376375}
              lng={35.3941036}
              text="ALAZ MÜHENDİSLİK"
            />
          </GoogleMapReact>
        </div>
        <div>
        <Alert>
          <h5 className="alert-heading">Adresimiz :</h5>
          <p>
            <Col xs="11">
              <h6>
                {" "}
                <FcFactory /> Gültepe Mahallesi Çatalan Caddesi No:129E İşyeri
                No:1 Sarıçam/ADANA{" "}
              </h6>
              <h6>
                {" "}
                <FcSms /> info@alazmuhendislik.com{" "}
              </h6>
              <h6>
                {" "}
                <FcAssistant /> 0553 287 62 75{" "}
              </h6>{" "}
            </Col>
          </p>
          </Alert>
        </div>
      </div>
    );
  }
}
