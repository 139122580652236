import { SET_ALERT,RESET_ALERT } from "../actions/actionTypes";
import initialState from "./initialState"

// const initialState ={
//     text: ' sepete eklendi',
//     colorAlert: 'success'
// }

export const alertReducer = (state = initialState.alert, action) => {
    switch (action.type) {
        case SET_ALERT:
            return action.msg
        case RESET_ALERT:
            return initialState
        default:
            return state
    }
}