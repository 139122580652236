import React, { useState, useEffect } from "react";
//import { Link, useNavigate } from "react-router-dom";   // react-router-dom  --> Versiyon 6 sonrası geçerli
import { useHistory } from "react-router-dom";
import alertify from "alertifyjs";

function UserInfo() {
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordconfirm, setPasswordConfirm] = useState("");
  const [isim, setIsim] = useState("");
  const [soyisim, setSoyisim] = useState("");
  const [tel, setTel] = useState("+90");
  const [adres, setAdres] = useState("");
  const [sifre, setSifre] = useState("");
  const [sifreError, setSifreError] = useState("");
  const [mailError, setMailError] = useState(""); 
  const [isimError, setIsimError] = useState("");
  const [soyisimError, setSoyisimError] = useState("");
  const [telError, setTelError] = useState("");
  const [adresError, setAdresError] = useState("");
  // const navigate = useNavigate();    // react-router-dom  --> Versiyon 6 sonrası geçerli
  let history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      // navigate("/");   // react-router-dom  --> Versiyon 6 sonrası geçerli
      history.push("/login");
    } else {
      let url = "https://www.alazmuhendislik.com/php-backend/users.php/";
      const formData = new FormData();
      formData.append("kullanicilar_id", localStorage.getItem("user-info"));
      formData.append("siparislerim", 0);
      fetch(url, {
        method: "POST",
        body: formData,
      })
        //  .then(response => response.text())
        //  .then(result => console.log(result));
        .then((response) => response.json())
        .then((result) => {
          // alertify.success(JSON.stringify(result),5);
          // alertify.success(result[0].kullanicilar_id,5);
          setId(result[0].kullanicilar_id);
          setIsim(result[0].kullanicilar_isim);
          setSoyisim(result[0].kullanicilar_soyisim);
          setEmail(result[0].kullanicilar_mail);
          setTel(result[0].kullanicilar_tel);
          setAdres(result[0].adres);
          setSifre(result[0].sifre);
        });
    }
  }, []);

  useEffect(() => {
    let rjxisim = /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
    let isValidisim = rjxisim.test(isim);
    if (isim === "") {
      setIsimError("İsim boş bırakılamaz");
    } else if (!isValidisim) {
      setIsimError("İsim afabetik olmalı");
    } else {
      setIsimError("");
    }
    let isValidsoyisim = rjxisim.test(soyisim);
    if (soyisim === "") {
      setSoyisimError("Soyisim boş bırakılamaz");
    } else if (!isValidsoyisim) {
      setSoyisimError("Soyisim afabetik olmalı");
    } else {
      setSoyisimError("");
    }
    // let isValidsifre = rjxisim.test(sifre);
    if (
      !(password && passwordconfirm && oldpassword) &&
      !(!password && !passwordconfirm && !oldpassword)
    ) {
      setSifreError("Şifreler boş bırakılamaz");
    } else if (oldpassword !== "" && sifre !== oldpassword) {
      setSifreError("Mevcut Şifre Yanlış");
    } else if (password !== passwordconfirm) {
      setSifreError("Yeni Şifreler Aynı Değil");
    } else {
      setSifreError("");
    }
    let rjxtel = /^(?:(?:\+90|0)(\d{3})(\d{3})(\d{2})(\d{2}))$/;
    let isValidtel = rjxtel.test(tel);
    if (tel === "") {
      setTelError("Telefon numaranız boş bırakılamaz");
    } else if (!isValidtel) {
      setTelError("Telefon numaranızı kontrol ediniz");
    } else {
      setTelError("");
    }

    let rjxmail =/^^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    let isValidmail = rjxmail.test(email);
    if (email === "") {
      setMailError("Mail boş bırakılamaz");
    } else if (!isValidmail) {
      setMailError("Mailinizi kontrol ediniz");
    } else {
      setMailError("");
    }

    if (!adres) {
      setAdresError("Adres boş bırakılamaz");
    } else {
      setAdresError("");
    }
  }, [
    isim,
    soyisim,
    password,
    passwordconfirm,
    oldpassword,
    sifre,
    tel,
    email,
    adres,
  ]);

  function register() {
    let newpass = "";
    if (password) {
      newpass = password;
    } else {
      newpass = sifre;
    }

    if (
      sifreError == "" &&
      mailError == "" &&
      isimError == "" &&
      soyisimError == "" &&
      telError == "" &&
      adresError == ""
    ) {
      let url =
        "https://www.alazmuhendislik.com/php-backend/update-register.php/";
      const formData = new FormData();
      formData.append("kullanicilar_id", id);
      formData.append("kullanicilar_isim", isim);
      formData.append("kullanicilar_soyisim", soyisim);
      formData.append("kullanicilar_mail", email);
      formData.append("kullanicilar_tel", tel);
      formData.append("sifre", newpass);
      formData.append("adres", adres);
      fetch(url, {
        method: "POST",
        body: formData,
      })
        //  .then(response => response.text())
        //  .then(result => console.log(result));
        .then((response) => response.json())
        .then((result) => {
          if (result == null) {
            alertify.error("Kayıt Başarısız Bilgilerinizi Kontrol Ediniz");
          } else if (result == "KayitliMail") {
            alertify.error("Daha Önce Kayıtlı Mail Adresi");
          } else {
            alertify.success("Kayıt Başarılı", 2);
            setTimeout(() => {
              window.location.href = "/hesapbilgilerim";
            }, 2000);
          }
        });
    } else {
    }
  }
  return (
    <div>
      <h1>Hesap Bilgilerim</h1>
      <div className="col-sm-6 offset-sm-3">
        Ad
        <input
          type="text"
          placeholder="Ad"
          value={isim}
          onChange={(event) => {
            setIsim(event.target.value);
          }}
          className="form-control"
        />
        {isimError == "" ? null : <a style={{ color: "red" }}>{isimError}</a>}
        <br />
        Soyad
        <input
          type="text"
          placeholder="Soyad"
          value={soyisim}
          onChange={(event) => {
            setSoyisim(event.target.value);
          }}
          className="form-control"
        />
        {soyisimError == "" ? null : (
          <a style={{ color: "red" }}>{soyisimError}</a>
        )}
        <br />
        Email
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          className="form-control"
        />
        {mailError == "" ? null : <a style={{ color: "red" }}>{mailError}</a>}
        <br />
        Telefon
        <input
          type="tel"
          placeholder="Telefon"
          value={tel}
          maxLength={13}
          onChange={(event) => {
            setTel(event.target.value);
          }}
          className="form-control"
        />
        {telError == "" ? null : <a style={{ color: "red" }}>{telError}</a>}
        <br />
        Adres
        <input
          type="text"
          placeholder="Adres"
          value={adres}
          onChange={(event) => {
            setAdres(event.target.value);
          }}
          className="form-control"
        />
        {adresError == "" ? null : <a style={{ color: "red" }}>{adresError}</a>}
        <br />
        Mevcut Şifre
        <input
          type="password"
          placeholder="Mevcut Şifre"
          onChange={(event) => {
            setOldPassword(event.target.value);
          }}
          className="form-control"
        />
        <br />
        Yeni Şifre
        <input
          type="password"
          placeholder="Yeni Şifre"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          className="form-control"
        />
        <br />
        Yeni Şifre Tekrar
        <input
          type="password"
          placeholder="Yeni Şifre Tekrar"
          onChange={(event) => {
            setPasswordConfirm(event.target.value);
          }}
          className="form-control"
        />
        {sifreError == "" ? null : <a style={{ color: "red" }}>{sifreError}</a>}
        <br />
        <button onClick={register} className="btn btn-primary">
          Güncelle
        </button>
      </div>
    </div>
  );
}
export default UserInfo;
