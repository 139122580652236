import React, { Component } from "react";
import { Alert } from "reactstrap";

export default class Gizlilik extends Component {
  render() {
    window.scrollTo(0,0);
    return (
      <div>
        <Alert>
          <h2 className="alert-heading" >
            GİZLİLİK VE GÜVENLİK POLİTİKASI
          </h2>
          <hr />
          <h5 className="alert-heading">GİZLİLİK</h5>
          <p>
            Kişisel Veriler sınırlama olmaksızın hakkınızda tanımlayıcı
            nitelikteki herhangi bir bilgi demektir. Bu tanım adınız, adresiniz,
            size özel tanımlanan şifreler, kayıtlı telefon numarası, e-posta
            adresi, finans bilgileri, kayıt adı, şifre ve diğer bilgileri
            içermektedir. Web sitesi üzerinden sipariş vermek için bu kişisel
            bilgilerin web sitesinde adınıza açılacak bir internet hesabı ile
            kaydedilmesi zorunludur. Bu bilgilerin temin edilmemesi halinde web
            sitesi üzerinden sipariş ve satış işleminin gerçekleştirilmesi
            mümkün değildir.
          </p>
          <hr />
          <h5 className="alert-heading">KAYITLI KULLANICILAR</h5>
          <p>
            alazmuhendislik.com siteyi gezmek için kayıt olmanıza gerek yoktur.
            Kayıt olmadan gerçekleştirilen siparişlerde, sunulmuş olan veriler,
            siparişin işlemden geçirilmesi ve sonraki alışverişlerinizin mümkün
            olan en kolay ve hızlı şekilde gerçekleştirilmesi için verilmesi
            gereken isim, adres, telefon numarası, e-mail adresi, finansal
            bilgiler, şifreler ve kişisel bilgiler alazmuhendislik.com veri
            tabanında saklanacaktır.
          </p>
          <p>
            Kayıt sonrasında web sitesi kanalı ile verilecek sipariş teklifleri
            tarafımızdan kabul edilene kadar geçerli ve bağlayıcı bir sözleşme
            niteliği taşımaz. Ancak tüketici teklifi/icabı ile bağlı olacaktır.
          </p>
          <p>
            Eğer kullanıcılar sistemin kendilerini otomatik olarak tanımasını
            isterse, superga.com.tr bu kullanıcılardan yalnızca farklı bir
            bilgisayardan alazmuhendislik.com’da oturum açtıklarında veya geçici
            internet dosyalarını (geçici bellek ve/veya tanımlama bilgileri)
            silmiş olduklarında kendilerini tanıtmalarını isteyecektir.
          </p>
          <p>
            Kayıtlı bir kullanıcı olarak, dilerseniz normal posta veya e-posta
            üzerinden alazmuhendislik.com yayımları ile ilgili bilgiler size
            gönderilecektir.
          </p>
          <hr />

          <h5 className="alert-heading">GÜVENLİK VE GİZLİLİK</h5>
          <p>
            Herhangi bir zamanda günlük, haftalık e-mail gönderim listemizden
            çıkmak isterseniz, gönderdiğimiz e-maillerin alt kısmında bulunan
            “E-bülten listemizden çıkmak için lütfen tıklayınız” linkine
            tıklayarak e-bülten üyeliğinden tek tıkla kolayca çıkabilirsiniz.
          </p>
          <p>
            Sipariş aşamasında bulunduğunuz web sitesi yüksek güvenlik
            programları ile koruma altındadır. Ancak internet üzerinden bilgi
            iletiminin tamamen güvenli olarak sağlanması olanaklı değildir. Bu
            nedenle garanti edilemeyen iletimler risk altında olabilir. Bu
            konuda tarafınızdan da gerekli güvenlik koşullarına uygun
            davranılması gerekmektedir.
          </p>
          <p>
            Şirketimizin web sitesinde yer alan Satın Alma koşulları ve Gizlilik
            Politikası koşulları ile ilgili olarak Web sitesi ana sayfasında
            değişiklik yapma hakkımız saklıdır. Yasalar ile zorunlu hale gelmesi
            nedeni ile yapılan değişiklikler haricindeki diğer değişiklikleri
            kabul etmezseniz, irtibat adresimize yazılı olarak bilgi geçmeniz
            gerekmektedir. Periyodik olarak bu Gizlilik Politikası mevcut
            şartlarını gözden geçirmek için bu sayfayı ziyaret etmelisiniz.
          </p>
          <hr />
          <p>
            <strong>Alaz Mühendislik</strong>{" "}
          </p>
          <p>
            Adres: Gültepe Mahallesi Çatalan Caddesi No:137 İşyeri No:1 <br />
            Sarıçam / Adana / Türkiye
            <br />
            Telefon: +90 536 615 86 91
            <br />
            E-posta: info@alazmuhendislik.com
          </p>
        </Alert>
      </div>
    );
  }
}
