import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import 'react-medium-image-zoom/dist/styles.css'
import { Button, Table, Row, Col } from 'reactstrap';
import mqtt from "mqtt/dist/mqtt";
import alertify from "alertifyjs";
const username = 'alazmuhendislik';
const password = 'Aziz514012';
var options = { username, password, 
  key: 'client.key',
  cert: 'client.crt',
  ca: 'ca.crt',
  rejectUnauthorized: true};
const newLocal = 'ws://3.69.146.99:9001';
const client = mqtt.connect(newLocal, options);

export default function DevicesFrecon(props) {
    const [rSelected, setRSelected] = useState(null);
    const [frekans, setFrekans] = useState(0);
    const [maxFrekans, setMaxFrekans] = useState(0);
    const [amper, setAmper] = useState(0);
    const [cikisVoltaj, setCikisVoltaj] = useState(0);
    const [rpm, setRpm] = useState(0);
    const [dcVoltaj, setDcVoltaj] = useState(0);
    const [input, setInput] = useState(2);
    const [timeOut, setTimeOut] = useState(1);
    const [icon, setIcon] = useState();
    const [maxPower, setMaxPower] = useState(0);
    const [trip0, setTrip0] = useState(0);
    const [trip1, setTrip1] = useState(0);
    const [trip2, setTrip2] = useState(0);
    const [trip3, setTrip3] = useState(0);
    const [trip4, setTrip4] = useState(0);
    const [parameterPage1, setParameterPage1] = useState(false);
    const [esp32, setEsp32] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alarm, setAlarm] = useState(1);
    const [synchronousFrequency, setSynchronousFrequency] = useState(0);
    const [tempofInverter, setTempofInverter] = useState(0);
    const [accuPoweronTime, setAccuPoweronTime] = useState(0);
    const [accuRunTime, setAccuRunTime] = useState(0);
    const [cumuPoweronTime, setCumuPoweronTime] = useState(0);
    const [cumuRunTime, setCumuRunTime] = useState(0);



    let { serverName } = useParams();


    // useEffect(() => {
    //     console.log(input);
    //     if (input != 0) {
    //       setIcon(require('./resimler/startg.png'));
    //     } else {
    //       setIcon(require('./resimler/startr.png'));
    //     }
    //     console.log(icon);
    //   }, [input]);

    useEffect(() => {
        const client = mqtt.connect(newLocal, options);
        console.log('USEEFFECT!');
        console.log(serverName);
        if (serverName != '') {
            client.on('connect', () => {
                console.log('connected!');
                alertify.alert('Servera Bağlandı');
                client.publish(serverName + '/baglan1', '1', function () {
                    console.log('Kullanıcı Bağlandı');
                    //client.end(); // Close the connection after publish
                });
            });
            client.subscribe(serverName + '/in');
            client.subscribe(serverName + '/parameterReadPub');
            client.subscribe(serverName + '/alarmKontrol');
            setLoading(true);

            client.on('message', (topic, payload, packet) => {
                console.log(topic, ' : ', payload.toString());
                setLoading(false);
                switch (topic) {
                    case serverName + '/in':
                        setInput(payload.toString());
                        break;
                    case serverName + '/parameterReadPub':
                        setEsp32(true);
                        var [
                            frekans,
                            maxFrekans,
                            outV,
                            timeOutStr,
                            kullaniciBaglantiStr,
                            outA,
                            maxPower,
                            outRpm,
                            dcBus,
                            synchronousFrequency,
                            tempofInverter,
                            accuPoweronTime,
                            accuRunTime,
                            cumuPoweronTime,
                            cumuRunTime,
                            trip0Pub,
                            trip1Pub,
                            trip2Pub,
                            trip3Pub,
                            trip4Pub,
                        ] = payload.toString().split(',');
                        setFrekans(frekans / 100);
                        setMaxFrekans(maxFrekans / 100);
                        setCikisVoltaj(outV);
                        setTimeOut(timeOutStr);
                        setAmper(outA / 10);
                        setMaxPower(maxPower / 10);
                        setRpm(outRpm);
                        setDcVoltaj(dcBus);
                        setSynchronousFrequency(synchronousFrequency / 100);
                        setTempofInverter(tempofInverter);
                        setAccuPoweronTime(accuPoweronTime);
                        setAccuRunTime(accuRunTime / 10);
                        setCumuPoweronTime(cumuPoweronTime);
                        setCumuRunTime(cumuRunTime);
                        setTrip0(trip0Pub);
                        setTrip1(trip1Pub / 100);
                        setTrip2(trip2Pub / 100);
                        setTrip3(trip3Pub);
                        setTrip4(trip4Pub);

                        if (kullaniciBaglantiStr == 0) {
                            goBack();
                        }
                        break;
                    case serverName + '/alarmKontrol':
                        setAlarm(payload.toString());
                        break;
                }
            });
        }
        return () => {
            client.end();
        };
    }, [serverName]);

    const cikis = () => {
        localStorage.removeItem("user-devices-info");
        // window.location.reload();
    };

    const _onPressStart = () => {
        setRSelected(1);
        client.publish(serverName + '/inSub', '1', function () {
            console.log('Motor Açıldı');
            alertify.alert('MOTOR AÇILDI');
        });

    };

    const _onPressStop = () => {
        setRSelected(2);
        client.publish(serverName + '/inSub', '0', function () {
            console.log('Motor Kapatıldı');
            alertify.alert('MOTOR KAPATILDI');
        });

    };

    const _alarmoff = () => {
        alertify.alert(
            'Alarm Devre Dışı Bırakılacak',
            'KABUL EDİYOR MUSUNUZ?',
            [
                {
                    text: 'HAYIR',
                    onPress: () => alertify.alert('ALARM HALA DEVREDE'),
                    style: 'cancel',
                },
                {
                    text: 'EVET',
                    onPress: () => {
                        client.publish(serverName + '/alarmKontrol', '0', function () {
                            console.log('ALARM DEVRE DIŞI');
                            alertify.alert('ALARM DEVRE DIŞI');
                            setAlarm(0);
                            //client.end(); // Close the connection after publish
                        });
                    },
                },
            ],
            { cancelable: false },
        );
    };

    // {localStorage.getItem("user-devices-info") ? (
    //     <li class="nav-item active">
    //         <a class="nav-link" href="/" onClick={() => this.cikis()} style={{ color: "#ff6600" }}>
    //           Çıkış
    //         </a>
    //     </li>
    //   ) : (
    //     <li class="nav-item active">
    //       <Link
    //         class="nav-link"
    //         to="/devices"
    //         style={{ color: "#ff6600" }}
    //       >
    //         Cihazlarım
    //       </Link>
    //     </li>
    //   )}

    const _alarmon = () => {
        alertify.alert(
            'Alarm Devreye Alınacak',
            'KABUL EDİYOR MUSUNUZ?',
            [
                {
                    text: 'HAYIR',
                    onPress: () => alertify.alert('ALARM HALA DEVRE DIŞI'),
                    style: 'cancel',
                },
                {
                    text: 'EVET',
                    onPress: () => {
                        client.publish(serverName + '/alarmKontrol', '1', function () {
                            console.log('ALARM DEVREYE ALINDI');
                            alertify.alert('ALARM DEVREYE ALINDI');
                            setAlarm(1);
                            //client.end(); // Close the connection after publish
                        });
                    },
                },
            ],
            { cancelable: false },
        );
    };

    const goBack = () => {
        // navigation.reset({
        //   routes: [{name: 'Login'}],
        // });
        //signOut();
        // const pushAction = StackActions.pop(1);
        // navigation.dispatch(pushAction);
    };


    if (window.innerWidth > 700) {
        return (
            <div
                style={{
                    backgroundImage: "url(" + "https://www.alazmuhendislik.com/resimler/uzaktan-kontrol.png" + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100vh'

                }}>

                <div> <br />  <h2 style={{ textAlign: "center", color: "#ff6600" }} >Hoşgeldin {serverName}</h2></div>  <br />
                {/* <h1 style={{ textAlign: "center", color:  }}>Motor Sürücü Uzaktan Kontrol Cihazı</h1> */}
                {/* {serverName} */}

                <div >
                    <Row>
                        <Col sm="1" ></Col>
                        <Col sm="5">
                            <Button
                                block
                                size="lg"
                                color="success"
                                outline
                                onClick={() => _onPressStart()}
                                active={rSelected === 2}
                            >
                                AÇ
                            </Button>
                        </Col>

                        <br />
                        <Col sm="5">
                            <Button
                                block
                                size="lg"
                                color="danger"
                                outline
                                onClick={() => _onPressStop()}
                                active={rSelected === 1}
                            >
                                KAPAT
                            </Button>
                        </Col>

                        <Col><br /> <h3 style={{ textAlign: "center", color: "white" }} >CİHAZIN DURUMU : {rSelected > 1 ? <h4>Kapalı</h4> : <h4> Açık</h4>}</h3>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col sm="1" ></Col>
                    <Col sm="5">
                        <div >
                            <br />
                            <Table bordered style={{ color: "white" }} >
                                <thead>
                                    <tr>
                                        <th>
                                            Parametreler
                                        </th>
                                        <th>
                                            Ölçülen Değer
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Anlık Frekans
                                        </td>
                                        <td>
                                            {frekans}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Maksimum Frekans
                                        </td>
                                        <td>
                                            {maxFrekans}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Çıkış Gerilimi
                                        </td>
                                        <td>
                                            {cikisVoltaj}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            DC Gerilimi
                                        </td>
                                        <td>
                                            {dcVoltaj}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Çıkış Akımı
                                        </td>
                                        <td>
                                            {amper}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Çıkış Gücü
                                        </td>
                                        <td>
                                            {maxPower}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            RPM
                                        </td>
                                        <td>
                                            {rpm}
                                        </td>

                                    </tr>

                                </tbody>
                            </Table>

                        </div>
                    </Col>
                    <Col sm="5">
                        <div>
                            <br />
                            <Table bordered style={{ color: "white" }} >
                                <thead>
                                    <tr>
                                        <th>
                                            Parametreler
                                        </th>
                                        <th>
                                            Ölçülen Değer
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Sıcaklık
                                        </td>
                                        <td>
                                            {tempofInverter}
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            Hata0
                                        </td>
                                        <td>
                                            {trip0}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Hata1
                                        </td>
                                        <td>
                                            {trip1}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Hata2
                                        </td>
                                        <td>
                                            {trip2}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Hata3
                                        </td>
                                        <td>
                                            {trip3}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Hata4
                                        </td>
                                        <td>
                                            {trip4}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Senkron Frekansı
                                        </td>
                                        <td>
                                            {synchronousFrequency}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div><br />
                    </Col>

                    <Button
                        block
                        size="lg"
                        color="danger"
                        outline
                        href="/"
                        onClick={() => cikis()}
                    >
                        Oturumu Kapat
                    </Button>
                </Row>

            </div>


        );
    }

    else if (window.innerWidth < 700) {

        return (
            <div
                style={{
                    backgroundImage: "url(" + "https://www.alazmuhendislik.com/resimler/cihazlar_arkaplan.webp" + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '160vh'

                }}>

                <div> <br />  <h2 style={{ textAlign: "center", color: "#ff6600" }} >Hoşgeldin {serverName}</h2></div>  <br />
                {/* <div>   <h2 style={{ textAlign: "center", color: "#ff6600" }}>Motor Sürücü Uzaktan Kontrol Cihazı</h2> <br /></div> */}
                {/* {serverName} */}

                <Row>
                    <Col xs="1" ></Col>
                    <Col xs="5">
                        <Button
                            block
                            size="lg"
                            color="success"
                            outline
                            onClick={() => _onPressStart()}
                            active={rSelected === 2}
                        >
                            AÇ
                        </Button>
                    </Col>
                    <br />
                    <Col xs="5">
                        <Button
                            block
                            size="lg"
                            color="danger"
                            outline
                            onClick={() => _onPressStop()}
                            active={rSelected === 1}
                        >
                            KAPAT
                        </Button>
                    </Col>

                    <Col><br /> <h4 style={{ textAlign: "center", color: "white" }} >CİHAZIN DURUMU : {rSelected > 1 ? <h5>Kapalı</h5> : <h5> Açık</h5>}</h4>
                        <br /> </Col>
                </Row>


                <Row>
                    <Col xs="1"></Col>

                    <br />
                    <Table bordered style={{ maxWidth: "80%", color: "white" }} >
                        <thead>
                            <tr>
                                <th>
                                    Parametreler
                                </th>
                                <th>
                                    Ölçülen Değer
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{ color: "cyan" }}>
                            <tr>
                                <td >
                                    Anlık Frekans
                                </td>
                                <td>
                                    {frekans}
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    Maksimum Frekans
                                </td>
                                <td>
                                    {maxFrekans}
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    Çıkış Gerilimi
                                </td>
                                <td>
                                    {cikisVoltaj}
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    DC Gerilimi
                                </td>
                                <td>
                                    {dcVoltaj}
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    Çıkış Akımı
                                </td>
                                <td>
                                    {amper}
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    Çıkış Gücü
                                </td>
                                <td>
                                    {maxPower}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    RPM
                                </td>
                                <td>
                                    {rpm}
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </Row>
                <br />

                <br />
                <div >
                    <Row>
                        <Col xs="1"></Col>
                        <Table bordered style={{ maxWidth: "80%", color: "white" }} >
                            <thead>
                                <tr>
                                    <th>
                                        Parametreler
                                    </th>
                                    <th>
                                        Ölçülen Değer
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ color: "yellow" }}>
                                <tr>
                                    <td>
                                        Sıcaklık
                                    </td>
                                    <td>
                                        {tempofInverter}
                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                        Hata0
                                    </td>
                                    <td>
                                        {trip0}
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        Hata1
                                    </td>
                                    <td>
                                        {trip1}
                                    </td>

                                </tr>


                                <tr>
                                    <td>
                                        Hata2
                                    </td>
                                    <td>
                                        {trip2}
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        Hata3
                                    </td>
                                    <td>
                                        {trip3}
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        Hata4
                                    </td>
                                    <td>
                                        {trip4}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Senkron Frekansı
                                    </td>
                                    <td>
                                        {synchronousFrequency}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <br />
                </div>
                <Button
                    block
                    size="lg"
                    color="danger"
                    outline
                    href="/"
                    onClick={() => cikis()}
                >
                    Oturumu Kapat
                </Button>
            </div>

        );
    }

}
