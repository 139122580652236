import React from "react";
import { Route, Switch } from "react-router-dom";
import Navi from "../navi/Navi";
import Dashboard from "./Dashboard";
import CartDetail from "../cart/CartDetail";
import AddOrUpdateProduct from "../products/AddOrUpdateProduct";
import NotFound from "../common/NotFound";
import ProductInfo from "../products/ProductInfo";
import ProductList from "../products/ProductList";
import IletisimDetail from "../iletisim/IletisimDetail";
import MesafeliSatis from "../iletisim/MesafeliSatis";
import Iade from "../iletisim/Iade";
import Gizlilik from "../iletisim/Gizlilik";
import Hakkimizda from "../iletisim/Hakkimizda";
import AltNavi from "../navi/AltNavi";
import Login from "../login/Login";
import Register from "../login/Register";
import UserInfo from "../user/UserInfo";
import CategoryPage from "../categories/CategoryPage";
import CategoryList from "../categories/CategoryList";
import DevicesFrecon from "../devices/DevicesFrecon";
import DevicesDelta from "../devices/DevicesDelta";
import DevicesNidec from "../devices/DevicesNidec";
import DevicesFrecon2 from "../devices/DevicesFrecon2";
import DeviceId from "../devices/DeviceId";
import Order from "../user/Order";
import OrderDetail from "../user/OrderDetail";

function App() {
  return (
     <div>
    {/*  <div className="App">  
       <Owldemo1/>  
     </div>   */}
      <div className="d-block w-100">
       
        <Navi />
        <br />
        <br />
        <br />
      </div>

      <Switch>
        <Route path="/categories" component={CategoryList} />  
        <Route path="/" exact component={Dashboard} />
        <Route path="/product" component={Dashboard} />
        

        <Route path="/devices" component={DeviceId} />
        <Route path="/devicesFrecon/:serverName" component={DevicesFrecon} />
        <Route path="/devicesNidec/:serverName" component={DevicesNidec} />
        <Route path="/devicesDelta/:serverName" component={DevicesDelta} />
        <Route path="/devicesFrecon2/:serverName" component={DevicesFrecon2} />

        <Route path="/saveproduct/:productId" component={AddOrUpdateProduct} />
        <Route path="saveproduct" component={AddOrUpdateProduct} />
        <Route path="/productdetail/:productId" component={ProductInfo} />
        <Route path="/categoryChange/:categoryId" component={CategoryPage} />
        <Route path="/products" component={ProductList} />
        <Route path="/cart" component={CartDetail} />
        <Route path="/iletisim" component={IletisimDetail} />
        <Route path="/satis-sozlesmesi" component={MesafeliSatis} />
        <Route path="/iade-sartlari" component={Iade} />
        <Route path="/gizlilik" component={Gizlilik} />
        <Route path="/hakkimizda" component={Hakkimizda} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/hesapbilgilerim" component={UserInfo} />
        <Route path="/siparislerim" component={Order} />
        <Route path="/siparislerimDetay/:siparisId" component={OrderDetail} />
        <Route component={NotFound} />
      </Switch>

      <div className="d-block w-100">
       <AltNavi />
      </div>
    </div>
  );
}
export default App;
