import React, { Component } from "react";
import { Alert, Row, Col, backgundImage } from "reactstrap";
import { Link } from "react-router-dom";
import {
  FcAssistant,
  FcSms,
  FcFactory,
} from "react-icons/fc";

export default class AltNavi extends Component {
  render() {

    if (window.innerWidth > 700) {
      return (
        <div style={{
           backgroundImage: "url(" + "https://www.alazmuhendislik.com/resimler/naviap.webp" + ")",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '65vh'
         
      }}>

          <br />  <br /><br /><br /><br />
          <Row>
            <Col sm="1" ></Col>
            <Col sm="3">
              <h5 style={{ color: "white" }}>ÖNEMLİ BİLGİLER </h5>
              <Link
                to="/satis-sozlesmesi"
                style={{ color: "#ff6600", fontSize: "1,6vw" }}
              >
                Mesafeli Satış Sözleşmesi <span class="sr-only"></span>
              </Link>
              <br />
              <Link
                to="/iade-sartlari"
                style={{ color: "#ff6600", fontSize: "1,6vw" }}
              >
                İptal ve İade Şartları <span class="sr-only"></span>
              </Link>
              <br />
              <Link
                to="/gizlilik"
                style={{ color: "#ff6600", fontSize: "1,6vw" }}
              >
                Gizlilik ve Çerez Politikası <span class="sr-only"></span>
              </Link>
              <br />
              <Link
                to="/hakkimizda"
                style={{ color: "#ff6600", fontSize: "1,6vw" }}
              >
                Hakkımızda <span class="sr-only"></span>
              </Link>
              <br />
              <Link
                to="/iletisim"
                style={{ color: "#ff6600", fontSize: "1,6vw" }}
              >
                İletişim <span class="sr-only"></span>
              </Link>
            </Col>

            <Col sm="3">
              <h5 style={{ color: "white" }}>HESABIM </h5>
              <Link to="/cart" style={{ color: "#ff6600", fontSize: "1,6vw" }}>
                Alışveriş Sepeti <span class="sr-only"></span>
              </Link>
              <br />
              <Link
                to="/siparislerim"
                style={{ color: "#ff6600", fontSize: "1,6vw" }}
              >
                Siparişlerim <span class="sr-only"></span>
              </Link>
              <br />

              <Link
                to="/register"
                style={{ color: "#ff6600", fontSize: "1,6vw" }}
              >
                Üye Ol<span class="sr-only"></span>
              </Link>
              <br />

              <Link
                to="/hesapbilgilerim"
                style={{ color: "#ff6600", fontSize: "1,6vw" }}
              >
                Hesap Bilgilerim <span class="sr-only"></span>
              </Link>
            </Col>
            <br />
            <Col xs="10" sm="4">
              <h5 style={{ color: "white" }}>İLETİŞİM </h5>
              <p style={{ fontSize: "1,6vw", color: "#ff6600" }}>
                <FcFactory /> Gültepe Mahallesi Çatalan Caddesi No:129E İşyeri
                No:1 Sarıçam/ ADANA
              </p>
              <p style={{ fontSize: "1,6vw", color: "#ff6600" }}>
                <FcSms /> info@alazmuhendislik.com
              </p>
              <p style={{ fontSize: "1,6vw", color: "#ff6600" }}>
                <FcAssistant /> 0553 287 62 75
              </p>
            </Col>
          </Row>
        
          {/* </Alert>  */}
        </div>

      );
    }
    else if (window.innerWidth < 700) {
      return (
        <div style={{
          backgroundImage: "url(" + "https://www.alazmuhendislik.com/resimler/12.png" + ")",
         backgroundPosition: 'center',
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat',
         height: '80vh'
        
     }}>
          
            <Row>
              <Col xs="1"></Col>
              <Col xs="11" >
              <br />
                <h5 style={{ color: "white" }}>ÖNEMLİ BİLGİLER </h5>
                <Link
                  to="/satis-sozlesmesi"
                  style={{ color: "#ff6600", fontSize: "1,5vw" }}
                >
                  Mesafeli Satış Sözleşmesi <span class="sr-only"></span>
                </Link>
                <br />
                <Link
                  to="/iade-sartlari"
                  style={{ color: "#ff6600", fontSize: "1,5vw" }}
                >
                  İptal ve İade Şartları <span class="sr-only"></span>
                </Link>
                <br />
                <Link
                  to="/gizlilik"
                  style={{ color: "#ff6600", fontSize: "1,5vw" }}
                >
                  Gizlilik ve Çerez Politikası <span class="sr-only"></span>
                </Link>
                <br />
                <Link
                  to="/hakkimizda"
                  style={{ color: "#ff6600", fontSize: "1,5vw" }}
                >
                  Hakkımızda <span class="sr-only"></span>
                </Link>
                <br />
                <Link
                  to="/iletisim"
                  style={{ color: "#ff6600", fontSize: "1,5vw" }}
                >
                  İletişim <span class="sr-only"></span>
                </Link>

              </Col>
              <Col xs="1"></Col>
              <Col xs="11" >
              <br />
                <h5 style={{ color: "white" }}>HESABIM </h5>

                <Link to="/cart" style={{ color: "#ff6600", fontSize: "1,5vw" }}>
                  Alışveriş Sepeti <span class="sr-only"></span>
                </Link>
                <br />


                <Link
                  to="/siparislerim"
                  style={{ color: "#ff6600", fontSize: "1,5vw" }}
                >
                  Siparişlerim <span class="sr-only"></span>
                </Link>
                <br />

                <Link
                  to="/register"
                  style={{ color: "#ff6600", fontSize: "1,5vw" }}
                >
                  Üye Ol<span class="sr-only"></span>
                </Link>
                <br />

                <Link
                  to="/hesapbilgilerim"
                  style={{ color: "#ff6600", fontSize: "1,5vw" }}
                >
                  Hesap Bilgilerim <span class="sr-only"></span>
                </Link>
              </Col>
              <br />
              <br />
              <Col xs="1"></Col>
              <Col xs="11" >
                <br /><br />
                <h5 style={{ color: "white" }}>İLETİŞİM </h5>
                <p style={{ fontSize: "1,5vw" ,color: "#ff6600"}}>
                  <FcFactory /> Gültepe Mahallesi Çatalan Caddesi No:129E İşyeri
                  No:1 Sarıçam/ADANA
                </p>
                <p style={{ fontSize: "1,5vw",color: "#ff6600" }}>
                  <FcSms /> info@alazmuhendislik.com
                </p>
                <p style={{ fontSize: "1,5vw" , color: "#ff6600"}}>
                  <FcAssistant /> 0553 287 62 75
                </p>
              </Col>
            </Row>
        </div>

      );
    }
  }
}