import React, { useState, useEffect } from "react";
//import { Link, useNavigate } from "react-router-dom";   // react-router-dom  --> Versiyon 6 sonrası geçerli
import { Link, useHistory } from "react-router-dom";
import alertify from "alertifyjs";

function DeviceId() {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [serverMarka, setServerMarka] = useState("");
    const [serverName, setServerName] = useState("");
    const [serverSifre, setServerSifre] = useState("");
    const [nameError, setNameError] = useState("");
    //const navigate = useNavigate();   // react-router-dom  --> Versiyon 6 sonrası geçerli
    let history = useHistory();
    useEffect(() => {
        if (localStorage.getItem("user-devices-info")) {
            //navigate("/");    // react-router-dom  --> Versiyon 6 sonrası geçerli
            history.push("/");
        }
    }, []);
    function login() {
        console.log(userName);
        console.log(password);
        if (userName == "" && password == "") {
            alertify.error("Kullanıcı Adı ve Şifre Boş Bırakılamaz");
        } else {
            let url = "https://www.alazmuhendislik.com/php-backend/loginMobil.php/";
            const formData = new FormData();
            formData.append("kullanici_name", userName);
            formData.append("sifre", password);
            formData.append("marka", serverMarka);
            fetch(url, {
                method: "POST",
                body: formData,
            })
                //  .then(response => response.text())
                //  .then(result => console.log(result));
                .then((response) => response.json())
                .then((result) => {
                    setServerName(result[0].kullanici_name);
                    setServerSifre(result[0].sifre);
                    setServerMarka(result[0].marka);
                    if (result === 'error') {
                        setNameError('Kullanıcı adı veya Şifre yanlış');
                    }
                    else {
                        localStorage.setItem("user-devices-info", result[0].kullanici_name);
                        localStorage.setItem("user-devices-brand", result[0].marka);
                        alertify.success("Giriş Başarılı", 2);
                        if (result[0].marka === 'Nidec') {
                            setTimeout(() => { window.location.href = "/devicesNidec/" + result[0].kullanici_name }, 2000);
                        } else if (result[0].marka === 'Delta') {
                            setTimeout(() => { window.location.href = "/devicesDelta/" + result[0].kullanici_name }, 2000);
                        } else if (result[0].marka === 'Frecon') {
                            setTimeout(() => { window.location.href = "/devicesFrecon/" + result[0].kullanici_name }, 2000);
                        } else if (result[0].marka === 'Frecon2') {
                            setTimeout(() => { window.location.href = "/devicesFrecon2/" + result[0].kullanici_name }, 2000);
                        }
                        ////signIn();/////////////////////////
                    }
                })
                .catch(error => {
                    console.log(error);
                }
                )
        }
    }
    return (
        <div>
            <h1 style={{ color: "#ff6600", textAlign: "center" }}>Giriş Yap</h1>
            <div className="col-sm-6 offset-sm-3">
                <input
                    type="text"
                    placeholder="Kullanıcı Adı"
                    onChange={(event) => {
                        setUserName(event.target.value);
                    }}
                    className="form-control"
                />
                <br />
                <input
                    type="password"
                    placeholder="Şifre"
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                    className="form-control"
                />
                <br />

            </div>
            <div style={{ textAlign: "center", justifyContent: "center" }}><button onClick={login} className="btn btn-secondary">
                Giriş Yap
            </button></div>
            <br /> <br />

        </div>
    );
}
export default DeviceId;
