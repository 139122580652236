import React, { useState, useEffect } from "react";
//import { Link, useNavigate } from "react-router-dom";   // react-router-dom  --> Versiyon 6 sonrası geçerli
import { Link,useHistory } from "react-router-dom";
import alertify from "alertifyjs";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //const navigate = useNavigate();   // react-router-dom  --> Versiyon 6 sonrası geçerli
  let history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      //navigate("/");    // react-router-dom  --> Versiyon 6 sonrası geçerli
      history.push("/");
    }
  }, []);
  function login() {
    console.log(email);
    console.log(password);
    if (email == "" && password == "") {
      alertify.error("Email ve Şifre Boş Bırakılamaz");
    } else {
      let url = "https://www.alazmuhendislik.com/php-backend/login.php/";
      const formData = new FormData();
      formData.append("kullanicilar_mail", email);
      formData.append("sifre", password);
      fetch(url, {
        method: "POST",
        body: formData,
      })
        //  .then(response => response.text())
        //  .then(result => console.log(result));
        .then((response) => response.json())
        .then((result) => {
          if (result == null) {
            alertify.error("Giriş Başarısız Bilgilerinizi Kontrol Ediniz");
          } else {
            localStorage.setItem("user-info", result[0].kullanicilar_id);
            alertify.success("Giriş Başarılı",2);
            setTimeout(() => {window.location.href = "/"}, 2000);
          }
        });
    }
  }
  return (
    <div>
      <h1 style={{color: "#ff6600", textAlign: "center"}}>Üye Girişi</h1>
      <div className="col-sm-6 offset-sm-3"  style={{alignItems:'center'}}>
        <input
          type="text"
          placeholder="Email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          className="form-control"
        />
        <br />
        <input
          type="password"
          placeholder="Şifre"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          className="form-control"
        />
        <br />
        
      </div>
<div style={{ textAlign: "center", justifyContent: "center" }}>
  <button style={{alignSelf: 'center'}} onClick={login} className="btn btn-secondary">
          Giriş Yap
        </button>
        <Link style={{ color: "#ff6600" }} class="nav-link" to="/register">Üye Ol</Link>
</div>

    </div>
  );
}
export default Login;
