import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../redux/actions/cartActions";
import { Table, Button, Row, Col } from "reactstrap";
import alertify from "alertifyjs";
import { Link } from "react-router-dom";

class CartDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { USD: 30 };
  }
  componentDidMount() {
    let url = "https://www.alazmuhendislik.com/php-backend/doviz.php/";
    fetch(url, {
      method: "POST"
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ USD: parseFloat(result[0]).toFixed(2) });
      });
  }
  removeFromCart(product) {
    this.props.actions.removeFromCart(product);
    alertify.error(product.productName + " sepetten silindi");
  }
  render() {
    let toplam = 0;
    let amount = 0;
    let sepet = [];
    let adet = [];
    let Kargo= 25;
    const formatter = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
    });
    window.scrollTo(0, 0);
    if (window.innerWidth > 700) {
      return (
        <div>

          <h3 style={{ fontSize: "1,5vw", textAlign: "center" }}>SEPET </h3>
          <br />

          <Table style={{ width: "1500px" }}>

            <thead>
              <th style={{ fontSize: "1.3vw", textAlign: "center" }}>ÜRÜN ADI</th>
              <th style={{ fontSize: "1.3vw", textAlign: "center" }}>BİRİM FİYATI</th>
              <th style={{ fontSize: "1.3vw", textAlign: "center" }}>KDV</th>
              <th style={{ fontSize: "1.3vw", textAlign: "center" }}>ADEDİ</th>
              <th style={{ fontSize: "1.3vw", textAlign: "center" }}>TOPLAM TUTAR</th>
            </thead>
            <tbody>
              {this.props.cart.map((cartItem) => (
                <tr key={cartItem.product.id}>
                  <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{cartItem.product.productName}</td>
                  <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{formatter.format(cartItem.product.unitPrice * this.state.USD / 1.2)}</td>
                  <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{formatter.format((cartItem.product.unitPrice * this.state.USD / 1.2) * 0.2)}</td>
                  <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{cartItem.quantity}</td>
                  <td style={{ fontSize: "1.4vw", textAlign: "center" }}>
                    {formatter.format(
                      (amount =
                        cartItem.quantity.toFixed(2) *
                        // cartItem.product.unitPrice.toFixed(2))
                        cartItem.product.unitPrice * this.state.USD)
                    )}
                  </td>
                  <td style={{ opacity: "0" }}>{(toplam = toplam + amount)}</td>
                  <td  >
                    <Button type="button" class="btn btn-primary btn-lg btn-block"
                      onClick={() => { this.removeFromCart(cartItem.product) }}
                      outline
                      color="danger"
                      style={{ fontSize: "1.2vw" }}
                    >
                      Sil
                    </Button>
                  </td>
                  <td style={{ opacity: "0" }}>{sepet.push(cartItem.product.id)}</td>
                  <td style={{ opacity: "0" }}>{adet.push(cartItem.quantity)}</td>
                </tr>

              ))}
            </tbody>

          </Table>
          <div>
            <br /><br />
            <Row>
              <Col sm="1"></Col>
              <Col sm="2" xs="4">
                <Button type="button" class="btn btn-primary btn-lg btn-block" color="danger" outline>
                  <a style={{ fontSize: "1.2vw" }} class="nav-link" color="black" href="/cart">
                    Sepeti Temizle
                  </a>
                </Button>
              </Col>
              <Col sm="2" xs="4">
                <Button type="button" class="btn btn-primary btn-lg btn-block" color="primary" outline>
                  <Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/cart">
                    Sepeti Güncelle
                  </Link>
                </Button>
              </Col>
              <Col sm="2" xs="4">
                {" "}
                <Button type="button" class="btn btn-primary btn-lg btn-block" color="warning" outline>
                  <Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/products">
                    Alışverişe Devam Et
                  </Link>
                </Button>
              </Col>
            </Row>
            <div class="d-flex justify-content-end">
              <Col sm="4 ">
                <Table bordered striped>
                  <tbody>
                    <tr>
                      <td style={{ fontSize: "1.4vw" }}>Sepet Toplamı</td>
                      <td style={{ fontSize: "1.4vw" }}>{formatter.format(toplam / 1.2)}</td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "1.4vw" }}> KDV (%20)</td>
                      <td style={{ fontSize: "1.4vw" }}>{formatter.format((toplam / 1.2) * 0.2)}</td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "1.4vw" }}>Kargo Ücreti</td>
                      {toplam >= 1000 || sepet==0 ? <td style={{ fontSize: "1.4vw" }}>{formatter.format(0)}</td>
                        : <td style={{ fontSize: "1.4vw" }}>{formatter.format(100)}</td>}
                    </tr> 
                    <tr>
                      <th style={{ fontSize: "1.4vw" }}>Genel Toplam</th>
                      {toplam >= 1000 || sepet==0 ? <th style={{ fontSize: "1.4vw" }}>{formatter.format(toplam)}</th>
                        : <th style={{ fontSize: "1.4vw" }}>{formatter.format(toplam + 100)}</th>}
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </div>

          </div>
          <div class="d-flex justify-content-end">

            {/* <Button color="success" outline><a href="https://www.alazmuhendislik.com/php-backend/iframe.php" outline color="success">Alışverişi Tamamla</a></Button> */}
            {toplam !== 0 ? (localStorage.getItem("user-info")
              ? (<form method='post' action='https://www.alazmuhendislik.com/php-backend/iframe.php'>
                <input style={{ opacity: "0" }} id='sepet' name='sepet' value={sepet} />
                <input style={{ opacity: "0" }} id='adet' name='adet' value={adet} />
                <input style={{ opacity: "0" }} id='toplam' name='toplam' value={toplam>=1000?toplam:toplam+100} />
                <input style={{ opacity: "0" }} id='user' name='user' value={localStorage.getItem("user-info")} />
                <input style={{ opacity: "0" }} id='usd' name='usd' value={this.state.USD} />
                <Button style={{ fontSize: "1vw" }} color="success" outline type='submit' name='satinal' value={'SatinAl'}>Alışverişi Tamamla</Button>

              </form>)
              : (
                <div>
                  <Button type="button" class="btn btn-primary btn-lg btn-block" color="success" outline><Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/login">Giriş Yap</Link></Button>
                  <Button type="button" class="btn btn-primary btn-lg btn-block" outline><Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/register">Üye Ol</Link></Button>
                </div>


              )) : null}
            {" "}
          </div>
          <br />   <br />
        </div>
      );
    }

    else if (window.innerWidth < 700) {
      return (
        <div>
          <h3 style={{ fontSize: "1,5vw", textAlign: "center" }}>SEPET </h3>
          <br />
          <Table>
            <thead>
              <th style={{ fontSize: "2vw", textAlign: "center" }}>ÜRÜN ADI</th>
              <th style={{ fontSize: "2vw", textAlign: "center" }}>ADEDİ</th>
              <th style={{ fontSize: "2vw", textAlign: "center" }}>TOPLAM TUTAR</th>
            </thead>
            <tbody>
              {this.props.cart.map((cartItem) => (
                <tr key={cartItem.product.id}>
                  <td style={{ fontSize: "2vw", textAlign: "center" }}>{cartItem.product.productName}</td>
                  <td style={{ fontSize: "2vw", textAlign: "center" }}>{cartItem.quantity}</td>
                  <td style={{ fontSize: "2vw", textAlign: "center" }}>
                    {formatter.format(
                      (amount =
                        cartItem.quantity.toFixed(2) *
                        cartItem.product.unitPrice * this.state.USD)
                    )}
                  </td>
                  <td style={{ opacity: "0" }}>{adet.push(cartItem.quantity)}</td>
                  <td style={{ opacity: "0" }}>{sepet.push(cartItem.product.id)}</td>
                  <td  >
                    <Button type="button" class="btn btn-primary btn-lg btn-block"
                      onClick={() => { this.removeFromCart(cartItem.product) }}
                      outline
                      color="danger"
                      style={{ fontSize: "2vw" }}
                    >
                      Sil
                    </Button>
                  </td>
                  <div style={{ opacity: "0" }}>{(toplam = toplam + amount)}</div>
                </tr>
              ))}
            </tbody>
          </Table>
          <div>
            <br />

            <div class="d-flex justify-content-end">
              <Col xs="5">
                <Button type="button" class="btn btn-primary btn-lg btn-block" color="danger" outline>
                  <a style={{ fontSize: "2vw" }} class="nav-link" color="black" href="/cart">
                    Sepeti Temizle
                  </a>
                </Button><br /><br />

                <Button type="button" class="btn btn-primary btn-lg btn-block" color="primary" outline>
                  <Link style={{ fontSize: "2vw" }} class="nav-link" color="black" to="/cart">
                    Sepeti Güncelle
                  </Link>
                </Button><br /><br />

                {" "}
                <Button type="button" class="btn btn-primary btn-lg btn-block" color="warning" outline>
                  <Link style={{ fontSize: "2vw" }} class="nav-link" color="black" to="/products">
                    Alışverişe Devam Et
                  </Link>
                </Button><br />
              </Col>

              <Col xs="6">
                <Table bordered striped>
                  <tbody>
                    <tr>
                      <td style={{ fontSize: "2vw" }}>Sepet Toplamı</td>
                      <td style={{ fontSize: "2vw" }}>{formatter.format(toplam / 1.20)}</td>
                    </tr>
                    <tr>
                    <td style={{ fontSize: "2vw" }}>Kargo Ücreti</td>
                      {toplam >= 300 || sepet==0 ? <td style={{ fontSize: "2vw" }}>{formatter.format(0)}</td>
                        : <td style={{ fontSize: "2vw" }}>{formatter.format(15)}</td>}
                    </tr>
                    <tr>
                      <td style={{ fontSize: "2vw" }}> KDV (%20)</td>
                      <td style={{ fontSize: "2vw" }}>{formatter.format((toplam / 1.20) * 0.20)}</td>
                    </tr>
                    <tr>
                    <th style={{ fontSize: "2vw" }}>Genel Toplam</th>
                      {toplam >= 300 || sepet==0 ? <th style={{ fontSize: "2vw" }}>{formatter.format(toplam)}</th>
                        : <th style={{ fontSize: "2vw" }}>{formatter.format(toplam + 25)}</th>}
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col xs="1"></Col>
            </div>
          </div>
          <div class="d-flex justify-content-end">

            {/* <Button color="success" outline><a href="https://www.alazmuhendislik.com/php-backend/iframe.php" outline color="success">Alışverişi Tamamla</a></Button> */}
            {toplam !== 0 ? (localStorage.getItem("user-info")
              ? (<form method='post' action='https://www.alazmuhendislik.com/php-backend/iframe.php'>
                <input style={{ opacity: "0" }} id='sepet' name='sepet' value={sepet} />
                <input style={{ opacity: "0" }} id='adet' name='adet' value={adet} />
                <input style={{ opacity: "0" }} id='toplam' name='toplam' value={toplam} />
                <input style={{ opacity: "0" }} id='user' name='user' value={localStorage.getItem("user-info")} />
                <input style={{ opacity: "0" }} id='usd' name='usd' value={this.state.USD} />
                <Button style={{ fontSize: "2.5vw" }} color="success" outline type='submit' name='satinal' value={'SatinAl'}>Alışverişi Tamamla</Button>
              </form>)
              : (
                <div>
                  <Button type="button" class="btn btn-primary btn-lg btn-block" color="success" outline><Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/login">Giriş Yap</Link></Button>
                  <Button type="button" class="btn btn-primary btn-lg btn-block" outline><Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/register">Üye Ol</Link></Button>
                </div>
              )) : null}
            {" "}
          </div>
          <br />
        </div>
      );
    }
  }

}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      removeFromCart: bindActionCreators(cartActions.removeFromCart, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CartDetail);


                    {/* <If condition={toplam >= 200}>
                        <td style={{ fontSize: "1.4vw" }}>{formatter.format(0)} </td>  </If>
                      <ElseIf condition={toplam <= 200}>
                        <td style={{ fontSize: "1.4vw" }}>{formatter.format(15)}</td>
                      </ElseIf>
                      <Else condition={toplam == 0}>
                        <td style={{ fontSize: "1.4vw" }}>{formatter.format(0)} </td>
                      </Else> */}