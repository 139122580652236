import * as actionTypes from "./actionTypes";

export function getProductsSuccess(products) {
  return { type: actionTypes.GET_PRODUCTS_SUCCESS, payload: products };
}

export function changeProduct(product) {
  return { type: actionTypes.CHANGE_PRODUCT, payload: product };
}

export function createProductSuccess(product) {
  return { type: actionTypes.CREATE_PRODUCT_SUCCESS, payload: product };
}

export function updateProductSuccess(product) {
  return { type: actionTypes.UPDATE_PRODUCT_SUCCESS, payload: product };
}

export function saveProductApi(product) {
    //let formaction = "add-product";
    //let url = "http://localhost:80/php-backend/action.php/";
    let url = 'https://www.alazmuhendislik.com/php-backend/add-product.php/';
    const formData = new FormData();
    
    // if (product.id) {
    //   formaction = formaction + "?productId=" + product.id;
    // }
    
    formData.append('id', product.id);
    formData.append('product', product);

    return fetch(url, {
    // method: product.id ? "PUT" : "POST",
    method: "POST",
    headers: { "content-type": "application/json" },
    body: formData
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveProduct(product) {
  return function(dispatch) {
    return saveProductApi(product)
      .then(savedProduct => {
        product.id
          ? dispatch(updateProductSuccess(savedProduct))
          : dispatch(createProductSuccess(savedProduct));
      })
      .catch(error => {
        throw error;
      });
  };
}

export async function handleResponse(response){
  if(response.ok){
    return response.json()
  }

  const error = await response.text()
  throw new Error(error)
}

export function handleError(error){
  console.error("Bir hata oluştu")
  throw error;
}

export function getProducts(categoryId) {
  return function(dispatch) {
    // let formaction = 'products';
    //let url = "http://localhost:80/php-backend/action.php/";
    let url = 'https://www.alazmuhendislik.com/php-backend/products.php/';
    const formData = new FormData();
    
    if (!categoryId) {
      categoryId = 0;
    }
    
    // formData.append('action', formaction);
    formData.append('categoryId', categoryId);
    return fetch(url, {
      method: 'POST',
      body: formData
    })
       .then(response => response.json())
       .then(result => dispatch(getProductsSuccess(result)));
      // .then(response => response.text())
      // .then(result => console.log(result));
  };
}
export function getPopularProducts(popularId) {
  return function(dispatch) {
    // let formaction = 'products';
    //let url = "http://localhost:80/php-backend/action.php/";
    let url = 'https://www.alazmuhendislik.com/php-backend/popularProducts.php/';
    const formData = new FormData();
    
    if (!popularId) {
      popularId = 1;
    }
    
    // formData.append('action', formaction);
    formData.append('popularId', popularId);
    return fetch(url, {
      method: 'POST',
      body: formData
    })
       .then(response => response.json())
       .then(result => dispatch(getProductsSuccess(result)));
      // .then(response => response.text())
      // .then(result => console.log(result));
  };
}
