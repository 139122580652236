import React, { Component } from "react";
import Chip from '@mui/material/Chip';
export default class AltNavi extends Component {
    render() {

        if (window.innerWidth > 700) {
            return (
                <div style={{
                    backgroundImage: "url(" + "https://www.alazmuhendislik.com/resimler/videoap.webp" + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '50vh'
                }}>
                    <div >
                        <section id="home-bottom" class="section">
                            <div class="container">
                                <div class="row no-gutters">
                                    <div class="col-sm-6 ">
                                        <br /><br /><br />
                                        <div class="bottom-item latest-video">
                                            <h4 style={{ color: "white", textAlign: "center" }}> VİDEOLAR</h4>
                                            <h5 style={{ color: "white", textAlign: "center", fontStyle: "oblique" }}>GSM ile Motor Sürücü Uzaktan Kontrolü</h5>
                                            <a target="_blank" href="https://www.youtube.com/channel/UCe7jKNLeqoMq3WGwWkN4Ssw/videos" >
                                                <Chip style={{
                                                    position: "absolute",
                                                    right: "40px",
                                                    top: "325px",
                                                }} color="warning" label="TÜM VİDEOLAR" variant="filled" />  </a>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <br /><br /><br />
                                        <iframe width="505vw" height="297vh"
                                            src="https://www.youtube.com/embed/UCWBOZv4WFg"
                                            title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen> </iframe>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br /><br /><br /><br /><br /><br />
                    </div>
                    <br />

                    {/* </Alert>  */}
                </div>
            );
        }
        else if (window.innerWidth < 700) {
            return (
                <div style={{
                    backgroundImage: "url(" + "https://www.alazmuhendislik.com/resimler/11.png" + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '80vh'
                }}>
                    <div >
                        <section id="home-bottom" class="section">
                            <div class="container">
                                <div class="row no-gutters">
                                    <div class="col-sm-6 ">
                                        <br />  <br />
                                        <div class="bottom-item latest-video">
                                            <h4 style={{ color: "white", textAlign: "center" }}> VİDEOLAR</h4>
                                            <h5 style={{ color: "white", textAlign: "center", fontStyle: "oblique" }}>GSM ile Motor Sürücü Uzaktan Kontrolü</h5>
                                            <a target="_blank" href="https://www.youtube.com/channel/UCe7jKNLeqoMq3WGwWkN4Ssw/videos" >
                                                <Chip style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "525px",
                                                }} color="warning" label="TÜM VİDEOLAR" variant="filled" />  </a>
                                        </div>
                                    </div>
                                    <div style={{
                                        textAlign: "center", alignItems: "center"
                                    }} class="col-sm-12">
                                        <br /><br /><br />
                                        <iframe width="350vw" height="250vh"
                                            src="https://www.youtube.com/embed/UCWBOZv4WFg"
                                            title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen> </iframe>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br /><br />
                    </div>


                    {/* </Alert>  */}
                </div>
            );
        }
    }
}