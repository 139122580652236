import React, { Component } from "react";
import {
  Alert,
  Col,
  Row,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardGroup,
} from "reactstrap";
import { FcAssistant, FcSms, FcFactory } from "react-icons/fc";

export default class Hakkimizda extends Component {
  render() {
    window.scrollTo(0,0);
    return (
      <div>
        <Alert>
          <h2 className="alert-heading">HAKKIMIZDA</h2>
          <hr />
          <h5 className="alert-heading">Alaz Mühendislik :</h5>
          <p>
            Yenilenebilir Enerji Sektöründe çalışmalar yapan Alaz Mühendislik
            güçlü vizyonu ve genç kadrosuyla sizlere hizmet vermektedir. Enerji
            tasarrufu için çeşitli çalışmalar yapan firmamız Güneş Enerjisi ve
            Rüzgar Enerjisi alanlarına yoğunluk vermektedir. Ayrıca tarımsal
            sulama alanında ve Endüstriyel alanlarda uzaktan kontrol sistemleri
            yapmaktadır. Yerli ve Milli ürünler geliştirerek ekonomiye katkı
            sağlamaktadır.
          </p>
          <hr />

          <Row>
            <CardGroup>
              <Card>
                <CardImg
                  alt="Card image cap"
                  src="https://www.alazmuhendislik.com/resimler/hakkimizda1.webp"
                  top
                  width="100%"
                />
                <CardBody>
                  <CardTitle tag="h5">Ev Sistemleri</CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    Akülü ve Aküsüz Bağ Evi Paketlerimiz için Bize Ulaşın
                  </CardSubtitle>
                </CardBody>
              </Card>

              <Card>
                <CardImg
                  alt="Card image cap"
                  src="https://www.alazmuhendislik.com/resimler/hakkimizda2.webp"
                  top
                  width="100%"
                />
                <CardBody>
                  <CardTitle tag="h5">Tarımsal Sulama</CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    Sulama Sistemlerinize Uzaktan Kontrolle Ulaşın
                  </CardSubtitle>
                </CardBody>
              </Card>
              <Card>
                <CardImg
                  alt="Card image cap"
                  src="https://www.alazmuhendislik.com/resimler/hakkimizda3.webp"
                  top
                  width="100%"
                />
                <CardBody>
                  <CardTitle tag="h5">Çatı GES </CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    İşyerinizin Elektriğini Güneşten Karşılayın
                  </CardSubtitle>
                </CardBody>
              </Card>
            </CardGroup>
          </Row>

          <h5 className="alert-heading">Adresimiz :</h5>
          <p>
            <Col xs="11">
              <h6>
                {" "}
                <FcFactory /> Gültepe Mahallesi Çatalan Caddesi No:129E İşyeri
                No:1 Sarıçam/ADANA{" "}
              </h6>
              <h6>
                {" "}
                <FcSms /> info@alazmuhendislik.com{" "}
              </h6>
              <h6>
                {" "}
                <FcAssistant /> 0553 287 62 75{" "}
              </h6>{" "}
            </Col>
          </p>
          <hr />
        </Alert>
      </div>
    );
  }
}
