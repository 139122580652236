import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from "reactstrap";
import { Link } from 'react-router-dom';

const images = [
  {

    url: "https://www.alazmuhendislik.com/resimler/w1.webp",
    title: 'Arazi Tip Güneş Enerji Santrali',
    width: '25%',

  },
  {
    url: "https://www.alazmuhendislik.com/resimler/w2.webp",
    title: ' Uzaktan Kontrol Sistemleri',
    width: '25%',
    link: '/categoryChange/2',
  },
  
  {
    url: "https://www.alazmuhendislik.com/resimler/w3.webp",
    title: 'Endüstriyel Çatı Ges',
    width: '25%',
  },
  {
    url: "https://www.alazmuhendislik.com/resimler/w4.webp",
    title: ' Mühendislik ve Projelendirme   ',
    width: '25%',
  },

]

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: '20vw',
  [theme.breakpoints.down('sm')]: {
    width: '50vw !important', // Overrides inline-style
    height: '50vw',
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 50%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.3,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export default function Works() {
  
    return (
      <div>
        <div>
          <ListGroup flush  style={{
        height: '40vh',
          textAlign:'center'

      }}>
            <ListGroupItem >
              <br/><br/>
              <ListGroupItemHeading >
                NELER YAPIYORUZ ?
              </ListGroupItemHeading>
              <br/>
              <ListGroupItemText>
                Yenilenebilir Enerji sektörüne inovatif yaklaşımımızla tüm Türkiye'ye çeşitli alanlarda hizmet veriyoruz.
                Ayrıca yerli ve milli ürünler geliştirerek ekonomiye katkı sağlıyoruz.
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
        </div>

        <Box style={{
          border: "20px", color: "white"
        }}
          sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%', height: '100%' }}>
          {images.map((image) => (
            <ImageButton
              focusRipple
              key={image.title}
              style={{
                width: image.width,
              }}
            >
              <Link to={image.link}>
              
              <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image >
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  sx={{
                    position: 'relative',
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}
                >
                  {image.title}
                  {<ImageMarked className="MuiImageMarked-root" />}
                </Typography>
              </Image>
              </Link>
            </ImageButton>
          ))}
        </Box>
      </div>
    );
  }


