import React, { Component } from "react";
import { Alert } from "reactstrap";

export default class Gizlilik extends Component {
  render() {
    window.scrollTo(0,0);
    return (
      <div>
        <Alert>
          <h2 className="alert-heading">MESAFELİ SATIŞ SÖZLEŞMESİ</h2>
          <hr />
          <p>
            İşbu Mesafeli Satış Sözleşmesi (bundan böyle “Sözleşme”) isim/unvan,
            adres ve iletişim bilgileri 3. maddede yer alan “ALICI” ile Gültepe
            Mahallesi Çatalan Caddesi No:137 İşyeri No:1 Sarıçam / Adana /
            Türkiye adresinde bulunan ALAZ MÜHENDİSLİK (“SATICI”) arasında
            aşağıda belirtilen hüküm ve şartlar çerçevesinde elektronik ortamda
            kurulmuştur.
          </p>
          <hr />
          <h5 className="alert-heading">1. SÖZLEŞMENİN KONUSU</h5>
          <p>
            İşbu Sözleşme’nin konusu, ALICI’nın, SATICI’ya ait web sitesi
            üzerinden elektronik ortamda siparişini verdiği aşağıda nitelikleri
            ve satış fiyatı belirtilen ürünün satışı ve teslimi ile ilgili 6502
            sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli
            Sözleşmeler Yönetmeliği hükümleri gereğince tarafların hak ve
            yükümlülüklerinin belirlenmesidir.
          </p>
          <hr />

          <h5 className="alert-heading">
            2. ALICININ ÖNCEDEN BİLGİLENDİRİLDİĞİ HUSUSLAR
          </h5>
          <p>
            ALICI, satışa konu mal veya hizmetlerin temel nitelikleri, satış
            fiyatı, ödeme şekli, teslimat koşulları ve satışa konu mal veya
            hizmetler ile ilgili tüm ön bilgiler ve “cayma” hakkı konusunda
            bilgi sahibi olduğunu, bu ön bilgileri elektronik ortamda teyit
            ettiğini ve sonrasında mal veya hizmetleri sipariş verdiğini işbu
            sözleşme hükümlerince kabul ve beyan eder. SATICI’nın web sitesinde
            ödeme sayfasında yer alan ön bilgilendirme ve fatura işbu
            sözleşmenin ayrılmaz parçalarıdır.
          </p>
          <hr />

          <h5 className="alert-heading">3. TARAFLAR</h5>
          <p>
            3.1. SATICI Bilgileri: <br />
            Unvanı : ALAZ MÜHENDİSLİK <br /> Adresi : Gültepe Mahallesi Çatalan
            Caddesi No:137 İşyeri No:1 Sarıçam / ADANA / Türkiye <br />
            Telefon : +90 536 615 86 91 <br />
            E-Posta Adresi : info@alazmuhendislik.com <br />
          </p>

          <p>
            {" "}
            3.2. ALICI Bilgileri:
            <br />
            Adı Soyadı / Unvanı : [kargo-kullanici-adi] [kargo-firma-adi]
            <br />
            Teslimat Adresi : [kargo-adresi]
            <br />
            Telefon : [telefon-no] <br />
            E-posta / Kullanıcı adı : [e-posta-adresi]
            <br />
          </p>

          <p>
            {" "}
            3.3. Fatura Bilgileri:
            <br />
            Adı / Soyadı / Unvanı : [fatura-kullanici-adi] [fatura-firma-adi]
            <br />
            Adres : [fatura-adresi]
            <br />
            Telefon : [telefon-no]
            <br />
            E-posta / Kullanıcı adı : [e-posta-adresi]
            <br />
            (Fatura Teslim: Fatura sipariş teslimatı sırasında teslimat adresine
            sipariş işe birlikte teslim edecektir. )<br />
          </p>
          <hr />

          <h5 className="alert-heading">4. SÖZLEŞME KONUSU</h5>
          <p>
            {" "}
            4.1. ALICI tarafından siparişi gerçekleştirilen ürünlerin detayları,
            vergiler dahil peşin satış tutarları ve adet bilgileri aşağıda
            belirtilmektedir. Aşağıdaki tabloda belirtilen ürünlerin tümü bundan
            sonra ÜRÜN olarak tanımlanmaktadır. <br />
            [sepet-tutar] <br />
            Teslimat Adresi :[kargo-adresi] <br /> Teslim Edilecek Kişi :
            [kargo-kullanici-adi][kargo-firma-adi] <br />
            Sipariş Tarihi : [tarih]{" "}
          </p>
          <p>
            4.2. Ürünün temel özellikleri (türü, miktarı, marka/modeli, rengi,
            adedi) SATICI’ya ait web sitesinde yer almaktadır.{" "}
          </p>

          <p>
            4.3. Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan
            edilen fiyatlar güncelleme yapılana ve değiştirilene kadar
            geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre
            sonuna kadar geçerlidir. <br />
          </p>
          <hr />

          <h5 className="alert-heading">5. ÜRÜNLERİN TESLİMATI</h5>
          <p>
            5.1. ÜRÜN, ALICI’nın İNTERNET SİTESİ üzerinde belirttiği teslimat
            adresine veya gösterdiği adresteki kişi/kuruluşa, faturası ile
            birlikte paketlenmiş ve sağlam olarak en geç 30 gün içinde teslim
            edilir, zorunluluk arz eden hallerde bu süre uzayabilir.{" "}
          </p>
          <p>
            {" "}
            5.2. ÜRÜN, ALICI’dan başka bir kişi/kuruluşa teslim edilecek ise,
            teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden SATICI
            sorumlu tutulamaz{" "}
          </p>

          <p>
            5.3. ALICI, ÜRÜN’ü teslim aldığı anda kontrol etmekle sorumlu olup;
            ezik, kırık, ambalajı yırtılmış vs. hasarlı ve ayıplı ürünlü teslim
            almayacaktır. ALICI; kargodan kaynaklanan bir sorun gördüğünde,
            ÜRÜN’ü kabul etmemekle ve KARGO firması yetkilisine tutanak
            tutturmakla sorumludur. Aksi halde SATICI sorumluluk kabul
            etmeyecektir.
          </p>
          <hr />

          <h5 className="alert-heading">6. TEMERRÜT HALİ</h5>
          <p>
            ALICI, kredi kartı ile yapmış olduğu işlemlerinde temerrüde düşmesi
            halinde kart sahibi bankanın kendisi ile yapmış olduğu kredi kartı
            sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu
            olacağını kabul, beyan ve taahhüt eder.
          </p>
          <hr />

          <h5 className="alert-heading">7. GENEL HÜKÜMLER</h5>
          <p>
            7.1. ALICI, web sitesinde gösterilen ürünlerin temel nitelikleri,
            satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri
            okuyup bilgi sahibi olduğunu ve elektronik ortamda satış için
            gerekli teyidi verdiğini kabul eder.{" "}
          </p>
          <p>
            7.2. ALICI; bu sözleşmeyi elektronik ortamda teyit etmekle, mesafeli
            sözleşmelerin akdinden önce, SATICI tarafından ALICI’ya verilmesi
            gereken adres, siparişi verilen ürünlere ait temel özellikler,
            ürünlerin vergiler dahil fiyatı, ödeme ve teslimat bilgilerini de
            doğru ve eksiksiz olarak edindiğini teyit etmiş olur.
          </p>
          <p>
            {" "}
            7.3. SATICI, sözleşme konusu ürünün sağlam, eksiksiz, siparişte
            belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım
            kılavuzları ile teslim edilmesinden sorumludur.
          </p>
          <p>
            {" "}
            7.4. SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan
            ALICI’yı bilgilendirmek ve onayını almak suretiyle eşit kalite ve
            fiyatta farklı bir ürün tedarik edebilir.
          </p>
          <p>
            {" "}
            7.5. SATICI, sipariş konusu ürün teslimatının imkânsızlaşması
            halinde Sözleşme konusu yükümlülüklerini yerine getiremezse, bu
            durumu, Sözleşme’den doğan ifa yükümlülüğünün süresi dolmadan
            ALICI’ya bildirir ve ALICI’ya eşit kalite ve fiyatta farklı bir ürün
            tedarik edebilir.
          </p>
          <p>
            7.6. Sözleşme konusu ürünün teslimatı için işbu Sözleşme’nin imzalı
            nüshasının SATICI’ya ulaştırılmış olması ve bedelinin ALICI’nın
            tercih ettiği ödeme şekli ile ödenmiş olması şarttır. Herhangi bir
            nedenle ürün bedeli ödenmez veya banka kayıtlarında iptal edilir
            ise, SATICI ürünün teslimi yükümlülüğünden kurtulmuş kabul edilir.
          </p>
          <p>
            {" "}
            7.7. ÜRÜN teslimatı sonrasında herhangi bir sebepten dolayı, işlem
            yapılan kredi kartının ait olduğu Banka/finansman kurumunun ÜRÜN
            bedelini SATICI’ya ödememesi halinde, ÜRÜN en geç 3 gün içinde ALICI
            tarafından tüm giderleri ALICI’ya ait olmak üzere SATICI’ya iade
            edilir. SATICI’nın ÜRÜN bedeli alacağını takip dahil diğer tüm
            akdi-kanuni hakları ayrıca ve her halükarda saklıdır.
          </p>
          <p>
            {" "}
            7.8. ÜRÜN’ün normal satış koşulları dışında olağanüstü durumlar
            (hava muhalefeti, deprem, sel, yangın gibi) nedeni ile 30 günlük
            süre zarfında teslim edilememesi ve gecikmenin 10 günü aşması söz
            konusu ise, SATICI teslimat ile ilgili olarak ALICI’yı
            bilgilendirir. Bu durumda ALICI siparişi iptal edebilir, benzer bir
            ürün sipariş edebilir veya olağanüstü durum sonuna kadar
            bekleyebilir. Sipariş iptallerinde ÜRÜN bedeli tahsil edilmiş ise
            iptalden itibaren 14 gün içinde ALICI’ya iade edilir. Kredi kartlı
            ödemelerde iade işlemi de ALICI kredi kartına iade sureti ile
            yapılır.
          </p>
          <p>
            {" "}
            7.9. ALICI, SATICI’ya ait web sitesine üye olurken kişisel ve diğer
            sair bilgilerin gerçeğe uygun olduğunu, SATICI’nın bu bilgilerin
            gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları, SATICI’nın
            ilk bildirimi üzerine derhal, nakden ve defaten tazmin edeceğin
            beyan ve taahhüt eder.
          </p>

          <hr />

          <h5 className="alert-heading">8. CAYMA HAKKI</h5>
          <p>
            8.1. SATICI, ALICI’nın hiçbir hukuki ve cezai sorumluluk
            üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı teslim aldığı
            veya sözleşmenin imzalandığı tarihten itibaren 14 (on dört) gün
            içerisinde malı veya hizmeti reddederek sözleşmeden cayma hakkının
            var olduğunu ve cayma bildiriminin SATICI’ya veya ürün sağlayıcıya
            ulaşması tarihinden itibaren malı geri almayı taahhüt eder.
          </p>
          <p>
            {" "}
            8.2. Cayma hakkının kullanılması için bu süre içinde SATICI’ya
            yazılı bildirimde bulunulması şarttır. Bu hakkın kullanılması
            halinde; <br />
            a- 3. kişiye veya ALICI’ya teslim edilen ÜRÜN’ün fatura aslı (iade
            edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun
            düzenlemiş olduğu iade faturası ile birlikte gönderilmesi
            gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri
            İADE FATURASI kesilmediği takdirde tamamlanmayacaktır. <br /> b-
            İade formu, <br />
            c- İade edilecek ürünlerin kutusu ambalajı, varsa standart
            aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim
            edilmesi gerekmektedir.
          </p>
          <p>
            {" "}
            8.3. SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en
            geç 14 gün içinde ÜRÜN bedeli ALICI’ya iade edilir. Kredi kartlı
            ödemelerde iade işlemi de ALICI kredi kartına iade sureti ile
            yapılır. Kredi kartına iade prosedürü aşağıda belirtilmiştir. <br />{" "}
            ALICI ürünü kaç taksit ile aldıysa Banka ALICI’ya geri ödemesini
            taksitle yapmaktadır. SATICI, bankaya ürün bedelinin tamamını tek
            seferde ödedikten sonra, Banka poslarından yapılan taksitli
            harcamaların ALICI’nın kredi kartına iadesi durumunda; konuya
            müdahil tarafların mağdur duruma düşmemesi ile talep edilen iade
            tutarları, yine taksitli olarak hamil taraf hesaplarına Banka
            tarafından aktarılır. ALICI’nın satış iptaline kadar ödemiş olduğu
            taksit tutarları, eğer iade tarihi ile kartın hesap kesim tarihileri
            çakışmazsa her ay karta 1 (bir) iade yansıyacak ve ALICI iade
            öncesinde ödemiş olduğu taksitleri bittikten sonra, iade öncesinde
            ödemiş olduğu taksitleri sayısı kadar ay daha alacak ve mevcut
            borçlarından düşmüş olacaktır.{" "}
          </p>
          <p>
            8.4. ALICI iade edeceği Malı ön bilgilendirme formunda belirtilen
            SATICI’nın anlaşmalı kargo şirketi ile SATICI’ya gönderdiği sürece
            iade kargo bedeli SATICI’ya aittir. ALICI’nın iade edeceği Malı ön
            bilgilendirme formunda belirtilen SATICI’nın anlaşmalı kargo şirketi
            dışında bir kargo şirketi ile göndermesi halinde iade kargo bedeli
            ve Malın kargo sürecinde uğrayacağı hasardan SATICI sorumlu
            değildir.
          </p>
          <hr />
          <h5 className="alert-heading">
            9. KİŞİSEL VERİLERİN KORUNMASI, TİCARİ ELEKTRONİK İLETİ ve
            FİKRİ-SINAİ HAKLAR İLE İLGİLİ KURALLAR
          </h5>
          <p>
            9.1. 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında
            kişisel veri olarak tanımlanabilecek ALICI’ya ait ad, soyad, e-posta
            adresi, T.C. Kimlik numarası, demografik veriler, mali veriler vb.
            bilgiler; * sipariş almak, ürün ve hizmetleri sunmak, ürün ve
            hizmetleri geliştirmek, sistemsel sorunların çözümü, ödeme
            işlemlerini gerçekleştirmek, -önceden onay verilmesi halinde-
            siparişler, ürünler ve hizmetler hakkında pazarlama faaliyetlerinde
            kullanılmak, ALICI’ya ait bilgilerin güncellenmesinde ve üyeliklerin
            yönetimi ve sürdürülmesi ile ALICI ile SATICI arasında kurulan
            mesafeli satış sözleşmesi ve sair sözleşmelerin ifası amacıyla ve 3.
            kişilerin teknik, lojistik ve benzeri diğer işlevlerinin SATICI
            adına yerine getirilmesini sağlamak için SATICI, SATICI iştirakleri
            ve üçüncü kişi ve/veya kuruluşlar tarafından süresiz/süreli
            kaydedilebilir, yazılı/manyetik arşivlerde muhafaza edilebilir,
            kullanılabilir, güncellenebilir, paylaşılabilir, transfer olunabilir
            ve sair suretler ile işlenebilir. </p>
            <p>9.2. ALICI’lara, yürürlükteki
            mevzuata uygun şekilde, her türlü ürün ve hizmetlere ilişkin
            tanıtım, reklam, iletişim, promosyon, satış ve pazarlama amacıyla,
            kredi kartı ve üyelik bilgilendirme, işlem, uygulamaları için SATICI
            tarafından SMS/kısa mesaj, anlık bildirim, otomatik arama,
            bilgisayar, telefon, e-posta/mail, faks, diğer elektronik iletişim
            araçları ile ticari elektronik iletişimler yapılabilir, ALICI
            kendisine ticari elektronik iletiler gönderilmesini kabul etmiştir.</p>
            <p>9.3. ALICI tarafından İnternet Sitesi’nde girilen bilgilerin ve
            işlemlerin güvenliği için gerekli önlemler, SATICI tarafındaki kendi
            sistem altyapısında, bilgi ve işlemin mahiyetine göre günümüz teknik
            imkânları ölçüsünde alınmıştır. Bununla beraber, söz konusu bilgiler
            ALICI’ya ait cihazlardan girildiğinden ALICI tarafından korunmaları
            ve ilgisiz kişilerce erişilememesi için, virüs ve benzeri zararlı
            uygulamalara ilişkin olanlar dahil, gerekli tedbirlerin alınması
            sorumluluğu ALICI’ya aittir.</p>
             <p>9.4. ALICI SATICI’ya belirtilen
            iletişim kanallarından ulaşarak veri kullanımı-işlenmelerini ve/veya
            iletişimleri her zaman için durdurulmasını talep edebilir. ALICI’nın
            bu husustaki açık bildirimine göre, kişisel veri işlemleri ve/veya
            tarafına iletişimler yasal azami süre içinde durdurulur; ayrıca
            dilerse, hukuken muhafazası gerekenler ve/veya mümkün olanlar
            haricindeki bilgileri, veri kayıt sisteminden silinir ya da kimliği
            belli olmayacak biçimde anonim hale getirilir. ALICI isterse kişisel
            verilerinin işlenmesi ile ilgili işlemler, aktarıldığı kişiler,
            eksik veya yanlış olması halinde düzeltilmesi, düzeltilen bilgilerin
            ilgili üçüncü kişilere bildirilmesi, verilerin silinmesi veya yok
            edilmesi, otomatik sistemler ile analiz edilmesi sureti ile kendisi
            aleyhine bir sonucun ortaya çıkmasına itiraz, verilerin kanuna
            aykırı olarak işlenmesi sebebi ile zarara uğrama halinde giderilmesi
            gibi konularda SATICI’ya her zaman başvurabilir ve bilgi alabilir.
            Söz konusu başvurular incelenerek yasal süresi içerisinde ALICI’ya
            mevzuatta öngörülen sürelerde dönüş yapılacaktır. </p>
            <p> 9.5. Web Sitesi’ne
            ait her türlü bilgi ve içerik ile bunların düzenlenmesi, revizyonu
            ve kısmen/tamamen kullanımı konusunda; SATICI’nın anlaşmasına göre
            diğer üçüncü şahıslara ait olanlar hariç; tüm fikri-sınai haklar ve
            mülkiyet hakları SATICI’ya aittir.</p>
            <p> 9.6. Web Sitesi’nden ulaşılan
            diğer sitelerde kendilerine ait gizlilik-güvenlik politikaları ve
            kullanım şartları geçerlidir, oluşabilecek ihtilaflar ile menfi
            neticelerinden SATICI sorumlu değildir.
          </p>
          <hr />
          <h5 className="alert-heading">
            10. DELİL ANLAŞMASI ve YETKİLİ MAHKEME
          </h5>
          <p>
            Bu Sözleşmeden ve/veya uygulanmasından doğabilecek her türlü
            uyuşmazlığın çözümünde SATICI kayıtları (bilgisayar-ses kayıtları
            gibi manyetik ortamdaki kayıtlar dahil) kesin delil oluşturur;
            Bakanlıkça ilan edilen değere kadar Tüketici Hakem Heyetleri, aşan
            durumlarda ALICI’nın ve SATICI’nın yerleşim yerindeki Tüketici
            Mahkemeleri ve İcra Müdürlükleri yetkilidir.
          </p>
          <hr />
          <h5 className="alert-heading">11. YÜRÜRLÜK</h5>
          <p>
            İşbu 12 (oniki) maddeden ibaret Sözleşme, ALICI tarafından
            elektronik ortamda onaylanmak suretiyle akdedilmiş ve yürürlüğe
            girmiştir. Sözleşme konusu ürünün web sitesi üzerinden verilen
            siparişine ait ödemenin gerçekleşmesi durumunda ALICI, işbu
            sözleşmenin tüm koşullarını kabul etmiş sayılacak ve sözleşme
            yürürlüğe girecektir.
          </p>
          <hr />
          <p>
            12. ALICI bu Sözleşme’de ve ayrılmaz parçasını oluşturan sipariş
            formunda yazılı tüm koşulları ve açıklamaları okuduğunu, satış
            koşullarının ve sair tüm ön bilgileri aldığını, incelediğini ve
            tamamını kabul ettiğini beyan, kabul ve taahhüt eder.
          </p>
          <hr />
        </Alert>
      </div>
    );
  }
}
