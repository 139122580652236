import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../redux/actions/categoryActions";
import { ListGroup, ListGroupItem, Button, UncontrolledCollapse, CardBody, Card } from "reactstrap";
import { Badge } from "reactstrap";
import * as productActions from "../../redux/actions/productActions";
import { HiChevronDoubleDown } from "react-icons/hi";
import { Link } from "react-router-dom";

class CategoryList extends Component {
  componentDidMount() {
    this.props.actions.getCategories();
  }
  selectCategory = (category) => {
    this.props.actions.changeCategory(category);
    this.props.actions.getProducts(category.id);
    window.scrollTo(0,600);
  };

  render() {

    if (window.innerWidth > 700) {
      return (
        <div>
          <h4 > 
            <Badge color="warning">  &#160;  Kategoriler </Badge>
          </h4>
           
          <ListGroup striped>
            {this.props.categories.map((category) => (
              <ListGroupItem
                //active={category.id === this.props.currentCategory.id}
                onClick={() => this.selectCategory(category)}
                key={category.id}
              >
                {/* Kategori listesindeki ürünleri çağırma */}
                <Link to={"/categoryChange/" + category.id}>{category.categoryName}</Link>
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      )
    }
    else if (window.innerWidth < 700) {
      return (
        <div>
          <Button block
            color="warning"
            id="toggler"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              width: "innerWidth"
            }}
          >
             <a>
                <HiChevronDoubleDown style={{ color: "#000000" ,fontSize:"1,5vw"}} />  
                &nbsp; Kategoriler&nbsp; <HiChevronDoubleDown />
              </a>{" "}
            
          </Button>
          <UncontrolledCollapse toggler="#toggler">
          <ListGroup striped style={{
              textAlign: "center",
             
              
            }}>
            {this.props.categories.map((category) => (
              <ListGroupItem
                active={category.id === this.props.currentCategory.id}
                onClick={() => this.selectCategory(category)}
                key={category.id}
              >
                {category.categoryName}
              </ListGroupItem>
            ))}
          </ListGroup>
          </UncontrolledCollapse>
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    currentCategory: state.changeCategoryReducer,
    categories: state.categoryListReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      changeCategory: bindActionCreators(
        categoryActions.changeCategory,
        dispatch
      ),
      getProducts: bindActionCreators(productActions.getProducts, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
