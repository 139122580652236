import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../redux/actions/productActions";
import * as cartActions from "../../redux/actions/cartActions";
import alertify from "alertifyjs";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardGroup,
  Row,
  Col,
  Nav
} from "reactstrap";

class Popular extends Component {
  constructor(props) {
    super(props);
    this.state = { USD: 1 };
  }

  componentDidMount() {
    let url = "https://www.alazmuhendislik.com/php-backend/doviz.php/";
    fetch(url, {
      method: "POST"
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ USD: parseFloat(result[0]).toFixed(2) });
      });
    this.props.actions.getPopularProducts();
  }
  addToCart = (product) => {
    this.props.actions.addToCart({ quantity: 1, product });
    alertify.success(product.productName + " sepete eklendi");
  };

  selectProduct = (product) => {
    this.props.actions.changeProduct(product);
  };

  render() {
    const formatter = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
    });

    return (
      <div>
        <Row>
          <Nav>
            <h4 > <Badge style={{
                  position:"relative",
                  left: "30px"
                }} color="warning"> Popüler Ürünler</Badge> </h4>

            <li class="nav-item active">
              <Link
                class="nav-link"
                to="/products"
                style={{
                  color: "#ff6600",
                  position: "absolute",
                  right: "30px"
                }}
              >
                Tüm Ürünler
              </Link>
            </li>
          </Nav>
        </Row>
        <CardGroup>
          <Row>
          
            {this.props.products.map((product) => (
              <Col
              
                 sm="3" xs="6"
                onClick={() => this.selectProduct(product)}
                key={product.id}
              >

                <Card>

                  <CardBody>

                    <Link to={"/productdetail/" + product.id} >
                      {product.unitPriceMax == 0 ? null : <div style={{
                        position: "absolute", top: "10px", right: "20px"
                      }}>
                        <span
                          style={{
                            color: "#ff0000",
                            fontSize: "20px",
                            fontWeight: "bold"
                          }}
                        >
                          -%{(100 - (product.unitPrice * 100 / product.unitPriceMax)).toFixed(0)}
                        </span>
                      </div>}

                      <CardImg
                        width="50%"
                        src={product.imageUrl}
                        alt=""
                      />

                    </Link>
                    <CardTitle>
                      {/* <Link to={"/saveproduct/" + product.id}
                      >
                        {product.productName}
                      </Link> */}
                      {product.productName}
                    </CardTitle>

                    {product.unitPriceMax == 0 ? null :
                      <CardSubtitle>
                        <span
                          style={{
                            color: "#b5b5b5",
                            fontSize: "16px",
                            textDecorationLine: 'line-through'
                          }}
                        >
                          {formatter.format(product.unitPriceMax * this.state.USD)}
                        </span>
                      </CardSubtitle>}

                    <CardSubtitle>
                      <span
                        style={{
                          color: "#ff6600",
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "right"
                        }}
                      >
                        {formatter.format(product.unitPrice * this.state.USD / 1.20)}
                      </span>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: "%13px",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        +KDV
                      </span>
                    </CardSubtitle>
                    <br />
                    <div className="text-right">
                      <Button type="button" class="btn btn-primary btn-lg btn-block"
                        onClick={() => this.addToCart(product)}
                        outline
                        color="success"
                      >
                        Sepete Ekle
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </CardGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentCategory: state.changeCategoryReducer,
    currentProduct: state.changeProductReducer,
    products: state.productListReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getProducts: bindActionCreators(productActions.getProducts, dispatch),
      getPopularProducts: bindActionCreators(productActions.getPopularProducts, dispatch),
      addToCart: bindActionCreators(cartActions.addToCart, dispatch),
      changeProduct: bindActionCreators(productActions.changeProduct, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Popular);
