import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCategories } from "../../redux/actions/categoryActions";
import { saveProduct, changeProduct } from "../../redux/actions/productActions";
import { addToCart } from "../../redux/actions/cartActions";
import alertify from "alertifyjs";
import ProductNavi from "../navi/ProductNavi";
import Carousel from "react-bootstrap/Carousel";
import {
  Col,
  Row,
  Button,
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";
import { useParams } from "react-router";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function ProductInfo({ addToCart, ...props }) {
  let { productId } = useParams();
  const [product, setProduct] = useState({ ...props.product });
  const [alertCode, setAlertCode] = useState({ ...props.alert });
  // const [colorAlert, setColorAlert] = useState({ ...props.alert });
  // const [cart, setCart] = useState({ ...props.cart });
  const [adet, setAdet] = useState("1");
  const [image, setImage] = useState("");
  const [index, setIndex] = useState(0);
  const [urlTaksit, setUrlTaksit] = useState("");
  const [USD, setUSD] = useState(30);
  // const { text, colorAlert } = this.props.alert;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // setUrl("https://www.paytr.com/odeme/taksit-tablosu/v2?token=532526628798752443c1395e99865cfde3e704233094668b50c9eae5194e6e83&merchant_id=267584&amount=1000&taksit=0&tumu=0");
  useEffect(() => {
    let url = "https://www.alazmuhendislik.com/php-backend/doviz.php/";
    fetch(url, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((result) => {
        setUSD(parseFloat(result[0]).toFixed(2));
      });
  }, [USD]);
  useEffect(() => {
    // if (categories.length === 0) {
    //   getCategories();
    // }
    // setProduct({ ...props.product });

    let url = "https://www.alazmuhendislik.com/php-backend/product-detail.php/";
    const formData = new FormData();
    formData.append("id", productId);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      //  .then(response => response.text())
      //  .then(result => console.log(result));
      .then((response) => response.json())
      .then((result) => {
        // alertify.success(JSON.stringify(result),5);
        // alertify.success(result[0].kullanicilar_id,5);
        setProduct(result[0]);
        setUrlTaksit(
          "https://www.paytr.com/odeme/taksit-tablosu/v2?token=532526628798752443c1395e99865cfde3e704233094668b50c9eae5194e6e83&merchant_id=267584&amount=" +
            result[0].unitPrice * USD +
            "&taksit=0&tumu=0"
        );
      });
  }, [USD]);

  function handleCart(event) {
    event.preventDefault();
    if (Number(product.unitsInStock) < Number(adet)) {
      alertify.error(`${product.productName} En fazla ${product.unitsInStock} adet alınabilir`);
    } else {
      var carttt = { ...props.cart.find((c) => c.product.id === product.id) };
      if (
        Number(carttt.quantity) == Number(product.unitsInStock) ||
        Number(carttt.quantity) + Number(adet) > Number(product.unitsInStock)
      ) {
        alertify.error(`${product.productName} En fazla ${product.unitsInStock} adet alınabilir`);
      } else {
        for (let index = 0; index < adet; index++) {
          addToCart({ quantity: 1, product });
        }
        alertify.success(`${product.productName} sepete eklendi`);
      }
    }
  }
  window.scrollTo(0, 0);
  const formatter = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 2,
  });

  // const zoomProps = {width: 400, height: 250, zoomWidth: 500, img: product.imageUrl};

  return (
    <div>
      <Row>
        <Col sm="2"></Col>
        <Col sm="4">
          <Card body>
            <Carousel
              interval={null}
              autoPlay={false}
              variant="dark"
              activeIndex={index}
              onSelect={handleSelect}
              indicators={false}
              prevLabel=""
              nextLabel=""
            >
              <Carousel.Item>
                <Zoom zoomMargin={40}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={product.imageUrl}
                    alt="First slide"
                  />
                </Zoom>
              </Carousel.Item>
              {product.imageUrl1 ? (
                <Carousel.Item>
                  <Zoom zoomMargin={40}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={product.imageUrl1}
                      alt="Second slide"
                    />
                  </Zoom>
                </Carousel.Item>
              ) : null}
              {product.imageUrl2 ? (
                <Carousel.Item>
                  <Zoom zoomMargin={40}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={product.imageUrl2}
                      alt="Third slide"
                    />
                  </Zoom>
                </Carousel.Item>
              ) : null}
              {product.imageUrl3 ? (
                <Carousel.Item>
                  <Zoom zoomMargin={40}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={product.imageUrl3}
                      alt="Forth slide"
                    />
                  </Zoom>
                </Carousel.Item>
              ) : null}
              {product.imageUrl4 ? (
                <Carousel.Item>
                  <Zoom zoomMargin={40}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={product.imageUrl4}
                      alt="Fifth slide"
                    />
                  </Zoom>
                </Carousel.Item>
              ) : null}

              {product.videoUrl ? (
                <Carousel.Item>
                  <iframe
                    width="400"
                    height="417"
                    src={product.videoUrl}
                    title="YouTube video player"
                    frameborder="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true"
                  ></iframe>
                </Carousel.Item>
              ) : null}
            </Carousel>

            {/* <ReactImageZoom {...zoofProps} />  */}
            {/* <img
              src={product.imageUrl}
              alt="Detail Img"
              style={{ width: "75%", height: "40%" }}
            />  */}
            <ul class="product-color-variations">
              <li class={index === 0 ? "active" : "change_image"}>
                <img
                  src={product.imageUrl}
                  width="50"
                  onClick={() => handleSelect(0)}
                  alt="Img1"
                />
              </li>
              {product.imageUrl1 ? (
                <li class={index === 1 ? "active" : "change_image"}>
                  <img
                    src={product.imageUrl1}
                    width="50"
                    onClick={() => handleSelect(1)}
                    alt="Img2"
                  />
                </li>
              ) : null}
              {product.imageUrl2 ? (
                <li class={index === 2 ? "active" : "change_image"}>
                  <img
                    src={product.imageUrl2}
                    width="50"
                    onClick={() => handleSelect(2)}
                    alt="Img3"
                  />
                </li>
              ) : null}
              {product.imageUrl3 ? (
                <li class={index === 3 ? "active" : "change_image"}>
                  <img
                    src={product.imageUrl3}
                    width="50"
                    onClick={() => handleSelect(3)}
                    alt="Img4"
                  />
                </li>
              ) : null}
              {product.imageUrl4 ? (
                <li class={index === 4 ? "active" : "change_image"}>
                  <img
                    src={product.imageUrl4}
                    width="50"
                    onClick={() => handleSelect(4)}
                    alt="Img5"
                  />
                </li>
              ) : null}

              {product.videoUrl ? (
                <li class={index === 5 ? "active" : "change_image"}>
                  <img
                    src={product.videoImg}
                    width="40"
                    onClick={() => {
                      {
                        product.imageUrl4
                          ? handleSelect(5)
                          : product.imageUrl3
                          ? handleSelect(4)
                          : product.imageUrl2
                          ? handleSelect(3)
                          : product.imageUrl1
                          ? handleSelect(2)
                          : handleSelect(1);
                      }
                    }}
                    alt="Video5"
                  />
                </li>
              ) : null}
            </ul>
          </Card>
        </Col>

        <Col sm="5">
          <Card body>
            <CardTitle tag="h4">{product.productName}</CardTitle>
            <br />
            <CardText>
              <p>Fiyat : {formatter.format(product.unitPrice * USD)}</p>
              <p>Marka : {product.brand}</p>
              <p>Stok: {product.unitsInStock} adet</p>

              {product.unitPriceMax == 0 ? null : (
                <CardSubtitle>
                  <span
                    style={{
                      color: "#b5b5b5",
                      fontSize: "16px",
                      textDecorationLine: "line-through",
                    }}
                  >
                    {formatter.format(product.unitPriceMax * USD)}
                  </span>
                  <span
                    style={{
                      color: "#ff0000",
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginLeft: 50,
                    }}
                  >
                    -%
                    {(
                      100 -
                      (product.unitPrice * 100) / product.unitPriceMax
                    ).toFixed(0)}
                  </span>
                </CardSubtitle>
              )}
              <CardSubtitle>
                <span
                  style={{
                    color: "#ff6600",
                    fontSize: "30px",
                    fontWeight: "bold",
                  }}
                >
                  <br />
                  {formatter.format((product.unitPrice * USD) / 1.2)}
                </span>
                <span
                  style={{
                    color: "#000000",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  +KDV
                </span>
              </CardSubtitle>
            </CardText>
            <form onSubmit={handleCart}>
              {" "}
              Adet {"  "}
              <input
                style={{
                  width: "15vw",
                }}
                type={"number"}
                min={1}
                value={adet}
                onChange={(event) => {
                  setAdet(event.target.value);
                }}
              ></input>{" "}
              {product.unitsInStock < 1 ? (
                <Button outline disabled={true} color="danger">
                  Stokta Yok
                </Button>
              ) : (
                <Button outline color="success">
                  Sepete Ekle
                </Button>
              )}
            </form>
          </Card>
        </Col>

        <Col sm="1" xs="1"></Col>
      </Row>
      <Row>
        <Col sm="2"></Col>
        <Col sm="8">
          <div className="d-block w-100 ">
            <ProductNavi id={productId} url={urlTaksit} />
          </div>
        </Col>
        <Col sm="1"></Col>
      </Row>
    </div>
  );
}

export function getProductById(products, productId) {
  let product = products.find((product) => product.id == productId) || null;
  return product;
}

function mapStateToProps(state, ownProps) {
  const productId = ownProps.match.params.productId;
  const product =
    productId && state.productListReducer.length > 0
      ? getProductById(state.productListReducer, productId)
      : {};
  return {
    product,
    products: state.productListReducer,
    currentProduct: state.changeProductReducer,
    categories: state.categoryListReducer,
    alert: state.alert,
    cart: state.cartReducer,
  };
}

const mapDispatchToProps = {
  getCategories,
  saveProduct,
  addToCart,
  changeProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo);
