import React, { useState, useEffect } from "react";
import { Rating } from '@mui/material';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
} from "reactstrap";

const ProductNavi = ({ id,url }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [productInfo, setProductInfo] = useState("");
  // const [url, setUrl] = useState("");

  useEffect(() => {
    let url = "https://www.alazmuhendislik.com/php-backend/product-detail.php/";
    const formData = new FormData();
    formData.append("id", id);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      //  .then(response => response.text())
      //  .then(result => console.log(result));
      .then((response) => response.json())
      .then((result) => {
        // alertify.success(JSON.stringify(result),5);
        // alertify.success(result[0].kullanicilar_id,5);
        setProductInfo(result[0].productInfo);
      });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);

  // constructor(props) {
  //   super(props);

  //   this.toggle = this.toggle.bind(this);
  //   this.state = {
  //     activeTab: "1",
  //   };
  // }

  // toggle(tab) {
  //   if (this.state.activeTab !== tab) {
  //     this.setState({
  //       activeTab: tab,
  //     });
  //   }
  // }
  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === "1"}
            onClick={() => {
              setActiveTab("1");
            }}
          >
            Ürün Özellikleri
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "2"}
            onClick={() => {
              setActiveTab("2");
            }}
          >
            Ödeme Yöntemleri
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "3"}
            onClick={() => {
              setActiveTab("3");
            }}
          >
            Yorumlar
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "4"}
            onClick={() => {
              setActiveTab("4");
            }}
          >
            Ek Bilgiler
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col xs="12">
              <Table striped>
                <thead>
                  <tr>
                    <th>Teknik Özellikler</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div dangerouslySetInnerHTML={{ __html: productInfo }} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="2">
          <Row>
            <div id="paytr_taksit_tablosu"></div>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <h4>Yorum Yok</h4>

              <Rating name="size-medium" defaultValue={0} />

            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <h4></h4>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ProductNavi;
