import React, { useEffect, useState } from "react";
import alertify from "alertifyjs";
import ProductNavi from "../navi/ProductNavi";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Table,
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";
import { useParams } from "react-router";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function OrderDetail() {
  let { siparisId } = useParams();
  const [siparislerim, setSiparislerim] = useState([]);
  const [siparisDetay, setSiparisDetay] = useState('');
  const [adetler, setAdetler] = useState([]);
  const [birimler, setBirimler] = useState([]);
  const [adet, setAdet] = useState([]);
  const [sepet, setSepet] = useState([]);
  const [amount, setAmount] = useState([]);
  const [toplam, setToplam] = useState([]);
  const [USD, setUSD] = useState(30);

  useEffect(() => {
    let url = "https://www.alazmuhendislik.com/php-backend/doviz.php/";
    fetch(url, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((result) => {
        setUSD(parseFloat(result[0]).toFixed(2));
      });
  }, [USD]);

  useEffect(() => {
    let url = "https://www.alazmuhendislik.com/php-backend/users.php/";
    const formData = new FormData();
    formData.append("kullanicilar_id", siparisId);
    formData.append("siparislerim", 2);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      //  .then(response => response.text())
      //  .then(result => console.log(result));
      .then((response) => response.json())
      .then((result) => {
          console.log(result);
        setSiparisDetay(result[0]);
        setSiparislerim(result[1]);
        setAdetler(result[0].siparisler_urunadet.split(","))
        setBirimler(result[0].siparisler_aldigifiyat.split(","))
      });
  }, [USD]);

  
  window.scrollTo(0, 0);
  const formatter = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 2,
  });

  // const zoomProps = {width: 400, height: 250, zoomWidth: 500, img: product.imageUrl};

  if (window.innerWidth > 700) {
    return (
      <div>

        <h3 style={{ fontSize: "1,5vw", textAlign: "center" }}>SİPARİŞ DETAYLARI</h3>
        <br />

        <Table style={{ width: "1500px" }}>

          <thead>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>ÜRÜN ADI</th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>BİRİM FİYATI</th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>KDV</th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>ADEDİ</th>
            <th style={{ fontSize: "1.3vw", textAlign: "center" }}>TOPLAM TUTAR</th>
          </thead>
          <tbody>
          {siparislerim.map((siparis,index) => (
              <tr key={index}>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{siparis.productName}</td>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{formatter.format(Number(birimler[index]) / 1.2)}</td>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{formatter.format((Number(birimler[index]) / 1.2) * 0.2)}</td>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{adetler[index]}</td>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{formatter.format(adetler[index]*Number(birimler[index]))}</td>
                {/* <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{formatter.format(siparisDetay.siparisler_toplam)}</td> */}
                </tr>
                ))}
            {/* {siparislerim.map((siparis,index) => (
              <tr key={index}>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{siparis.productName}</td>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{formatter.format(siparis.unitPrice * USD / 1.2)}</td>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{formatter.format((siparis.unitPrice * USD / 1.2) * 0.2)}</td>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>{siparis.unitsInStock}</td>
                <td style={{ fontSize: "1.4vw", textAlign: "center" }}>
                  {formatter.format(
                    (amount =
                        siparis.unitsInStock.toFixed(2) *
                      // cartItem.product.unitPrice.toFixed(2))
                      siparis.unitPrice * USD)
                  )}
                </td>
                <td style={{ opacity: "0" }}>{(toplam = toplam + amount)}</td>
                <td  >
                  <Button type="button" class="btn btn-primary btn-lg btn-block"
                    onClick={() => { this.removeFromCart(cartItem.product) }}
                    outline
                    color="danger"
                    style={{ fontSize: "1.2vw" }}
                  >
                    Sil
                  </Button>
                </td>
                <td style={{ opacity: "0" }}>{sepet.push(siparis.id)}</td>
                <td style={{ opacity: "0" }}>{adet.push(siparis.unitsInStock)}</td>
              </tr>

            ))} */}
          </tbody>

        </Table>
        <div>
          <br /><br />
         
          <div class="d-flex justify-content-end">
            <Col sm="4 ">
              <Table bordered striped>
                <tbody>
                  <tr>
                    <td style={{ fontSize: "1.4vw" }}>Sepet Toplamı</td>
                    <td style={{ fontSize: "1.4vw" }}>{formatter.format((siparisDetay.siparisler_toplam>=1100?siparisDetay.siparisler_toplam:siparisDetay.siparisler_toplam-100)/1.2)}</td>
                  </tr>
                
                  <tr>
                    <td style={{ fontSize: "1.4vw" }}> KDV (%20)</td>
                    <td style={{ fontSize: "1.4vw" }}>{formatter.format(((siparisDetay.siparisler_toplam>=1100?siparisDetay.siparisler_toplam:siparisDetay.siparisler_toplam-100) / 1.2) * 0.2)}</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "1.4vw" }}>Kargo Ücreti</td>
                    {siparisDetay.siparisler_toplam >= 1100 ? <td style={{ fontSize: "1.4vw" }}>{formatter.format(Number(0))}</td>
                      : <td style={{ fontSize: "1.4vw" }}>{formatter.format(Number(100))}</td>}
                  </tr> 
                  <tr>
                    <th style={{ fontSize: "1.4vw" }}>Genel Toplam</th>
                    <th style={{ fontSize: "1.4vw" }}>{formatter.format(siparisDetay.siparisler_toplam)}</th>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </div>

        </div>
        
        {/* <div class="d-flex justify-content-end">

          {toplam !== 0 ? (localStorage.getItem("user-info")
            ? (<form method='post' action='https://www.alazmuhendislik.com/php-backend/iframe.php'>
              <input style={{ opacity: "0" }} id='sepet' name='sepet' value={sepet} />
              <input style={{ opacity: "0" }} id='adet' name='adet' value={adet} />
              <input style={{ opacity: "0" }} id='toplam' name='toplam' value={toplam} />
              <input style={{ opacity: "0" }} id='user' name='user' value={localStorage.getItem("user-info")} />
              <input style={{ opacity: "0" }} id='usd' name='usd' value={this.state.USD} />
              <Button style={{ fontSize: "1vw" }} color="success" outline type='submit' name='satinal' value={'SatinAl'}>Alışverişi Tamamla</Button>

            </form>)
            : (
              <div>
                <Button type="button" class="btn btn-primary btn-lg btn-block" color="success" outline><Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/login">Giriş Yap</Link></Button>
                <Button type="button" class="btn btn-primary btn-lg btn-block" outline><Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/register">Üye Ol</Link></Button>
              </div>


            )) : null}
          {" "}
        </div> */}
        <br />   <br />
      </div>
    );
  }

  else if (window.innerWidth < 700) {
    return (
      <div>
        <h3 style={{ fontSize: "1,5vw", textAlign: "center" }}>SEPET </h3>
        <br />
        <Table>
          <thead>
            <th style={{ fontSize: "2vw", textAlign: "center" }}>ÜRÜN ADI</th>
            <th style={{ fontSize: "2vw", textAlign: "center" }}>ADEDİ</th>
            <th style={{ fontSize: "2vw", textAlign: "center" }}>TOPLAM TUTAR</th>
          </thead>
          <tbody>
          {siparislerim.map((siparis,index) => (
              <tr key={index}>
                  {index==0?null:
                  <>
                <td style={{ fontSize: "2vw", textAlign: "center" }}>{siparis.productName}</td>
                <td style={{ fontSize: "2vw", textAlign: "center" }}>{siparis.unitsInStock}</td>
                <td style={{ fontSize: "2vw", textAlign: "center" }}>
                  {formatter.format(
                    (amount =
                        siparis.unitsInStock.toFixed(2) *
                      siparis.unitPrice * this.state.USD)
                  )}
                </td>
                <td style={{ opacity: "0" }}>{adet.push(siparis.unitsInStock)}</td>
                <td style={{ opacity: "0" }}>{sepet.push(siparis.id)}</td>
                {/* <td  >
                  <Button type="button" class="btn btn-primary btn-lg btn-block"
                    onClick={() => { this.removeFromCart(siparis) }}
                    outline
                    color="danger"
                    style={{ fontSize: "2vw" }}
                  >
                    Sil
                  </Button>
                </td> */}
                <div style={{ opacity: "0" }}>{(toplam = toplam + amount)}</div>
                </>}
              </tr>
            ))}
          </tbody>
        </Table>
        <div>
          <br />

          <div class="d-flex justify-content-end">
            <Col xs="5">
              <Button type="button" class="btn btn-primary btn-lg btn-block" color="danger" outline>
                <a style={{ fontSize: "2vw" }} class="nav-link" color="black" href="/cart">
                  Sepeti Temizle
                </a>
              </Button><br /><br />

              <Button type="button" class="btn btn-primary btn-lg btn-block" color="primary" outline>
                <Link style={{ fontSize: "2vw" }} class="nav-link" color="black" to="/cart">
                  Sepeti Güncelle
                </Link>
              </Button><br /><br />

              {" "}
              <Button type="button" class="btn btn-primary btn-lg btn-block" color="warning" outline>
                <Link style={{ fontSize: "2vw" }} class="nav-link" color="black" to="/products">
                  Alışverişe Devam Et
                </Link>
              </Button><br />
            </Col>

            <Col xs="6">
              <Table bordered striped>
                <tbody>
                  <tr>
                    <td style={{ fontSize: "2vw" }}>Sepet Toplamı</td>
                    <td style={{ fontSize: "2vw" }}>{formatter.format(toplam / 1.20)}</td>
                  </tr>
                  <tr>
                  <td style={{ fontSize: "2vw" }}>Kargo Ücreti</td>
                    {toplam >= 300 || sepet==0 ? <td style={{ fontSize: "2vw" }}>{formatter.format(0)}</td>
                      : <td style={{ fontSize: "2vw" }}>{formatter.format(15)}</td>}
                  </tr>
                  <tr>
                    <td style={{ fontSize: "2vw" }}> KDV (%20)</td>
                    <td style={{ fontSize: "2vw" }}>{formatter.format((toplam / 1.20) * 0.20)}</td>
                  </tr>
                  <tr>
                  <th style={{ fontSize: "2vw" }}>Genel Toplam</th>
                    {toplam >= 300 || sepet==0 ? <th style={{ fontSize: "2vw" }}>{formatter.format(toplam)}</th>
                      : <th style={{ fontSize: "2vw" }}>{formatter.format(toplam + 25)}</th>}
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs="1"></Col>
          </div>
        </div>
        <div class="d-flex justify-content-end">

          {/* <Button color="success" outline><a href="https://www.alazmuhendislik.com/php-backend/iframe.php" outline color="success">Alışverişi Tamamla</a></Button> */}
          {toplam !== 0 ? (localStorage.getItem("user-info")
            ? (<form method='post' action='https://www.alazmuhendislik.com/php-backend/iframe.php'>
              <input style={{ opacity: "0" }} id='sepet' name='sepet' value={sepet} />
              <input style={{ opacity: "0" }} id='adet' name='adet' value={adet} />
              <input style={{ opacity: "0" }} id='toplam' name='toplam' value={toplam} />
              <input style={{ opacity: "0" }} id='user' name='user' value={localStorage.getItem("user-info")} />
              <input style={{ opacity: "0" }} id='usd' name='usd' value={this.state.USD} />
              <Button style={{ fontSize: "2.5vw" }} color="success" outline type='submit' name='satinal' value={'SatinAl'}>Alışverişi Tamamla</Button>
            </form>)
            : (
              <div>
                <Button type="button" class="btn btn-primary btn-lg btn-block" color="success" outline><Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/login">Giriş Yap</Link></Button>
                <Button type="button" class="btn btn-primary btn-lg btn-block" outline><Link style={{ fontSize: "1.2vw" }} class="nav-link" color="black" to="/register">Üye Ol</Link></Button>
              </div>
            )) : null}
          {" "}
        </div>
        <br />
      </div>
    );
  }
}