import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  Row,
  Button,
} from "reactstrap";
import CartSummary from "../cart/CartSummary";


export default class Navi extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  cihazlar = () => {
    var user_device = localStorage.getItem("user-devices-info");
    var user_device_brand = localStorage.getItem("user-devices-brand");
    if (user_device_brand === 'Nidec') {
      setTimeout(() => { window.location.href = "/devicesNidec/" + user_device }, 2000);
    } else if (user_device_brand === 'Delta') {
      setTimeout(() => { window.location.href = "/devicesDelta/" + user_device }, 2000);
    } else if (user_device_brand === 'Frecon') {
      setTimeout(() => { window.location.href = "/devicesFrecon/" + user_device }, 2000);
    } else if (user_device_brand === 'Frecon2') {
      setTimeout(() => { window.location.href = "/devicesFrecon2/" + user_device }, 2000);
    }
  };

  cikisyap = () => {
    localStorage.removeItem("user-info");
    // window.location.reload();
  };
  cikis = () => {
    localStorage.removeItem("user-devices-info");
    // window.location.reload();
  };
  render() {
    window.scrollTo(0, 0);
    if (window.innerWidth > 700) {
      return (
        <div>
          <Row>
            <Navbar color="#000000" expand="md" fixed="top" light>
              {/* <Navbar color="light" light expand="md"> */}
              <NavbarBrand>
                <Link to="/">
                  <img
                    src="https://www.alazmuhendislik.com/resimler/logo.webp"
                    width="60%"
                    alt=""
                  />
                </Link>
              </NavbarBrand>
              <NavLink>
                <Button
                  color="warning"
                  outline
                  style={{ position: "fixed", right: "40px", bottom: "20px" }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Yukarı
                </Button>
              </NavLink>
              <NavbarToggler
                style={{
                  position: "fixed",
                  right: "33px",
                  top: "10px",
                }}
                onClick={this.toggle}
              />
              <Collapse
                isOpen={this.state.isOpen}
                style={{
                  position: "fixed",
                  right: "43px",
                  top: "25px",
                }}
                navbar
              >

                <Nav className="ml-auto" navbar>
                <CartSummary />
                  <Link
                    class="nav-link"
                    to="/products"
                    style={{ color: "#ff6600" }}
                  >
                    Mağaza
                  </Link>
                 
                  {/* {localStorage.getItem("user-devices-info") ? (
                    <li class="nav-item active">
                      <Link class="nav-link" onClick={() => this.cihazlar()} style={{ color: "#ff6600" }}>
                        Cihazlarım
                      </Link>
                    </li>
                  ) : (
                    <li class="nav-item active">
                      <Link
                        class="nav-link"
                        to="/devices"
                        style={{ color: "#ff6600" }}
                      >
                        Cihazlarım
                      </Link>
                    </li>
                  )} */}

                  {localStorage.getItem("user-info") ? (
                    <li class="nav-item active">
                      <a class="nav-link" href="/" onClick={() => this.cikisyap()} style={{ color: "#ff6600" }}>
                        Çıkış Yap
                      </a>
                    </li>
                  ) : (
                    <li class="nav-item active">
                      <Link
                        class="nav-link"
                        to="/login"
                        style={{ color: "#ff6600" }}
                      >
                        Giriş Yap
                      </Link>
                    </li>
                  )}
                </Nav>
              </Collapse>
            </Navbar>
          </Row>
        </div>
      );
    }

    else if (window.innerWidth < 700) {
      return (
        <div style={{ backgroundcolor: "white" }}>
          <Row>
            <Navbar color="#000000" expand="md" fixed="top" light>
              {/* <Navbar color="light" light expand="md"> */}
              <NavbarBrand>
                <Link to="/">
                  <img
                    src="https://www.alazmuhendislik.com/resimler/logo.webp"
                    width="60%"
                    alt=""
                  />
                </Link>
              </NavbarBrand>
              <NavLink>
                <Button
                  color="warning"
                  outline
                  style={{ position: "fixed", right: "40px", bottom: "20px" }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Yukarı
                </Button>
              </NavLink>
              <NavbarToggler
                style={{
                  position: "fixed",
                  right: "25px",
                  top: "15px",
                }}
                onClick={this.toggle}
              />
              <Collapse
                isOpen={this.state.isOpen}
                style={{
                  position: "fixed",
                  right: "38px",
                  top: "60px",
                }}
                navbar
              >
                <Nav style={{ background: 'white', width:"150%" }} className="ml-auto" navbar>
                  <Link
                    class="nav-link"
                    to="/products"
                    style={{ color: "#ff6600" , textAlign:"center" }}
                  >
                    Mağaza
                  </Link>


                  {/* {localStorage.getItem("user-devices-info") ? (
                    <li class="nav-item active">
                      <Link class="nav-link" onClick={() => this.cihazlar()} style={{ color: "#ff6600" ,textAlign:"center" }}>
                        Cihazlarım
                      </Link>
                    </li>
                  ) : (
                    <li class="nav-item active">
                      <Link
                        class="nav-link"
                        to="/devices"
                        style={{ color: "#ff6600" ,textAlign:"center"}}
                      >
                        Cihazlarım
                      </Link>
                    </li>
                  )} */}
                  {localStorage.getItem("user-info") ? (
                    <li class="nav-item active">
                      <a class="nav-link" href="/" onClick={() => this.cikisyap()} style={{ color: "#ff6600" ,textAlign:"center"}}>
                        Çıkış Yap
                      </a>
                    </li>
                  ) : (
                    <li class="nav-item active">
                      <Link
                        class="nav-link"
                        to="/login"
                        style={{ color: "#ff6600" ,textAlign:"center"}}
                      >
                        Giriş Yap
                      </Link>
                    </li>
                  )}

                  <CartSummary />
                </Nav>
              </Collapse>
            </Navbar>
          </Row>
        </div>

      )
    }
  }
}

