export const CHANGE_CATEGORY="CHANGE_CATEGORY"
export const GET_CATEGORIES_SUCCESS="GET_CATEGORIES_SUCCESS"

export const CHANGE_PRODUCT="CHANGE_PRODUCT"
export const GET_PRODUCTS_SUCCESS="GET_PRODUCTS_SUCCESS"
export const CREATE_PRODUCT_SUCCESS="CREATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_SUCCESS="UPDATE_PRODUCT_SUCCESS"

export const ADD_TO_CART="ADD_TO_CART"
export const REMOVE_FROM_CART="REMOVE_FROM_CART"

export const SET_ALERT="SET_ALERT"
export const RESET_ALERT="RESET_ALERT"
