import OwlCarousel from 'react-owl-carousel';
import React, { Component } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './owl.css';
import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    Col,
    Row,
} from "reactstrap";

export class Owldemo1 extends Component {
    render() {
        if (window.innerWidth > 700) {
            return (
                <div>
                    <div class='container-fluid' >
                        <div className="row title"  >

                        </div>
                    </div>
                    <Row >
                        <Col sm="2"></Col>
                        <Col sm="9">
                            <div class='fluid' >
                                <OwlCarousel
                                    items={3}
                                    className="owl-theme"
                                    autoplay
                                    loop
                                    nav
                                    margin={30} >
                                    
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/istanbul-.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Öz Tüketim Sistemi <br />Ümraniye/İstanbul</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/baz_istasyon.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Baz İstasyonu Akülü Sistemi  <br /> Altınözü/Hatay</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                   
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/g.antep-2hp.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> 2Hp Tarımsal Sulama Sistemi  <br /> Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                    <Card>

                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/ges_cayciftlik.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> GES Sistemi  <br /> Karataş/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/4kw-oz.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Şebeke Destekli Öztüketim Sistemi  <br /> Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karavan2.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Akülü Karavan Sistemi <br />Sarıçam/Adana</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/saricam.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Tarımsal Sulama ve Ev Sistemi <br />Sarıçam/Adana</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/kırıkhan-bagevi3.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Bağevi Paketi Sistemi  <br /> Kırıkhan/Hatay</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karatas.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi Karataş/Adana</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/yahya.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>4Hp Tarımsal Sulama Sistemi <br />Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>


                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karatas-2.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi <br />Karataş/Adana</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karavan1.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Akülü Karavan Sistemi <br />Sarıçam/Adana</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>
                                   
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/turkoglu.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi<br />Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/nigde.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi <br />Ulukışla/Niğde</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>

                                  

                                    <Card >

                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/turkoglu1.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>2Hp Tarımsal Sulama Sistemi Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>

                                  
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/goksun.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Bağevi Paketi Sistemi  <br /> Göksun/Kahramanmaraş</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/tashan.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>5.5Hp Tarımsal Sulama Sistemi Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/cura1.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>3Hp Tarımsal Sulama Sistemi Hassa/Hatay</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/yelken.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>3Hp Tarımsal Sulama Sistemi Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karaisali1.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi Karaisalı/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/Yumurtalık.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>DC Pompa ve Otomasyon Sistemi  <br /> Yumurtalık/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/mersin-bagevi2.webp" />


                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Akülü Paket Sistemi  <br /> Tarsus/Mersin</p>

                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>

                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/kmaras-dc.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> DC Pompa Sistemi  <br /> Osmaniye</p>
                                            </CardTitle>
                                        </CardBody>


                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/sarıcam.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Şebeke Destekli Öztüketim Sistemi  <br /> Sarıçam/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                </OwlCarousel>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (window.innerWidth < 700) {
            return (
                <div >
                    <div class='container-fluid' >
                        <div className="row title"  >
                            <div class="col-sm-12 btn btn-info">
                                HİZMETLERİMİZ
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col sm="8">
                            <div class='fluid' >
                                <OwlCarousel
                                    style={{ maxWidth: "80%", height: "70%", marginLeft: "10%" }}
                                    items={1}
                                    className="owl-theme"
                                    autoplay
                                    loop
                                    nav
                                >
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/baz_istasyon.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Baz İstasyonu Akülü Sistemi  <br /> Altınözü/Hatay</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img"
                                                src="https://www.alazmuhendislik.com/resimler/istanbul-.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Öz Tüketim Sistemi <br />Ümraniye/İstanbul</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/ges_cayciftlik.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> GES Sistemi  <br /> Karataş/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/g.antep-2hp.webp" />

                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> 2Hp Tarımsal Sulama Sistemi  <br /> Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>
                                        </CardBody>

                                    </Card>
                                   
                                  
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/4kw-oz.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Şebeke Destekli Öztüketim Sistemi  <br /> Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/yelken.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>3Hp Sulama Sistemi Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/kmaras-dc.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> DC Pompa Sistemi  <br /> Osmaniye</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/kırıkhan-bagevi3.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Bağevi Paketi Sistemi  <br /> Kırıkhan/Hatay</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karatas-2.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi <br />Karataş/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karatas-2.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi <br />Karataş/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karavan1.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Akülü Karavan Sistemi <br />Sarıçam/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img"
                                                src="https://www.alazmuhendislik.com/resimler/saricam.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Tarımsal Sulama ve Ev Sistemi <br />Sarıçam/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img"
                                                src="https://www.alazmuhendislik.com/resimler/turkoglu.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi<br />Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img"
                                                src="https://www.alazmuhendislik.com/resimler/nigde.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi <br />Ulukışla/Niğde</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                   
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img"
                                                src="https://www.alazmuhendislik.com/resimler/turkoglu1.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>2Hp Sulama Sistemi Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card >
                                        <CardBody style={{
                                            borderRadius: "50"
                                        }}>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/mersin-bagevi2.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Akülü Paket Sistemi  <br /> Tarsus/Mersin</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karatas.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi <br />Karataş/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karavan2.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Akülü Karavan Sistemi <br />Sarıçam/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/tashan.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>5.5Hp Sulama Sistemi Türkoğlu/Kahramanmaraş</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/cura1.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>3Hp Sulama Sistemi <br />Hassa/Hatay</p>
                                            </CardTitle>

                                        </CardBody>
                                    </Card>

                                   

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/karaisali1.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}>Bağevi Paketi Sistemi <br /> Karaisalı/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/Yumurtalık.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> DC Pompa ve Otomasyon Sistemi  <br /> Yumurtalık/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <CardImg
                                                className="img" src="https://www.alazmuhendislik.com/resimler/sarıcam.webp" />
                                            <CardTitle>
                                                <p class="resimYazisi" style={{
                                                    fontWeight: "bold", color: "black", fontSize: "20px"
                                                }}> Şebeke Destekli Öztüketim Sistemi  <br /> Sarıçam/Adana</p>
                                            </CardTitle>
                                        </CardBody>
                                    </Card>

                                </OwlCarousel>
                            </div>
                        </Col>
                    </Row>
                </div>

            )
        }
    }
}

export default Owldemo1;