import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const items = [
  {
    id: 1,
    src: "https://www.alazmuhendislik.com/resimler/ges.png",
    
  },
  {
    id: 2,
    src: "https://www.alazmuhendislik.com/resimler/slide7.webp",
    baglanti: "https://play.google.com/store/apps/details?id=com.alazkontroltarr"
  },
  {
  id: 3,
  src: "https://www.alazmuhendislik.com/resimler/slide9.webp",
    baglanti: "https://www.ziraatbank.com.tr/tr/kurumsal/tarim/gunes-enerjili-sulama-kredisi"
   
   },
  {
    id: 4,
    src: "https://www.alazmuhendislik.com/resimler/slider10.webp",
    url: '/categoryChange/3'
  },
  {
    id: 5,
    src: "https://www.alazmuhendislik.com/resimler/slide3.webp",
    url: '/categoryChange/2'
  },
  {
    id: 6,
    src: "https://www.alazmuhendislik.com/resimler/slide4.webp",
    url: '/categoryChange/1'
  },
  //  {
  //    id: 6,
  //    src: "https://www.alazmuhendislik.com/resimler/slider10.webp",

  // },
  // {
  //   id: 6,
  //   src: "https://www.alazmuhendislik.com/resimler/slide5.webp",
  //   // altText: 'Slide 1',
  //   // caption: 'Slide 1',
  //   // url:'/categoryChange/2'
  // },

]
function Carousellink() {
  return (

    <Carousel prevLabel="" nextLabel="" autoPlay>
      {items.map((item) => {
        return (
          <Carousel.Item style={{

            position: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',

          }}>
            {item.baglanti
              ? <a target="_blank" href={item.baglanti}>
                <img
                  style={{
                    width: '100vw',
                    height: "88vh"
                  }}
                  src={item.src}
                // alt={item.altText}
                /></a>
              : <Link to={item.url}>
                <img
                  style={{
                    width: '100vw',
                    height: "88vh"
                  }}
                  src={item.src}
                // alt={item.altText}
                />
              </Link>}

            {/* <Carousel.Caption>
              <h3>{item.caption}</h3>
              <p>{item.caption}</p>
            </Carousel.Caption> */}
          </Carousel.Item>
        );
      })}

    </Carousel>
  );
}
export default Carousellink;


