import React, { useState, useEffect } from "react";
//import { Link, useNavigate } from "react-router-dom";   // react-router-dom  --> Versiyon 6 sonrası geçerli
import { Link, useHistory } from "react-router-dom";
import alertify from "alertifyjs";
import {
  FormGroup,
  Input,
  Label
} from "reactstrap";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordconfirm, setPasswordConfirm] = useState("");
  const [isim, setIsim] = useState("");
  const [soyisim, setSoyisim] = useState("");
  const [tel, setTel] = useState("+90");
  const [adres, setAdres] = useState("");
  const [sifreError, setSifreError] = useState("");
  const [mailError, setMailError] = useState("");
  const [isimError, setIsimError] = useState("");
  const [soyisimError, setSoyisimError] = useState("");
  const [telError, setTelError] = useState("");
  const [adresError, setAdresError] = useState("");
  // const navigate = useNavigate();    // react-router-dom  --> Versiyon 6 sonrası geçerli
  let history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      // navigate("/");   // react-router-dom  --> Versiyon 6 sonrası geçerli
      history.push("/");
    }
  }, []);

  useEffect(() => {
    let rjxisim = /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
    let isValidisim = rjxisim.test(isim);
    if (isim === "") {
      setIsimError("İsim boş bırakılamaz");
    } else if (!isValidisim) {
      setIsimError("İsim afabetik olmalı");
    } else {
      setIsimError("");
    }
    let isValidsoyisim = rjxisim.test(soyisim);
    if (soyisim === "") {
      setSoyisimError("Soyisim boş bırakılamaz");
    } else if (!isValidsoyisim) {
      setSoyisimError("Soyisim afabetik olmalı");
    } else {
      setSoyisimError("");
    }
    // let isValidsifre = rjxisim.test(sifre);
    if (!(password && passwordconfirm)) {
      setSifreError("Şifreler boş bırakılamaz");
    } else if (password !== passwordconfirm) {
      setSifreError("Şifreler Aynı Değil");
    } else {
      setSifreError("");
    }
    let rjxtel = /^(?:(?:\+90|0)(\d{3})(\d{3})(\d{2})(\d{2}))$/;
    let isValidtel = rjxtel.test(tel);
    if (tel === "") {
      setTelError("Telefon boş bırakılamaz");
    } else if (!isValidtel) {
      setTelError("Telefon numaranızı kontrol ediniz");
    } else {
      setTelError("");
    }

    let rjxmail = /^^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    let isValidmail = rjxmail.test(email);
    if (email === "") {
      setMailError("Mail boş bırakılamaz");
    } else if (!isValidmail) {
      setMailError("Mailinizi kontrol ediniz");
    } else {
      setMailError("");
    }

    if (!adres) {
      setAdresError("Adres boş bırakılamaz");
    } else {
      setAdresError("");
    }
  }, [isim, soyisim, password, passwordconfirm, tel, email, adres]);

  function register() {
    if (
      sifreError == "" &&
      mailError == "" &&
      isimError == "" &&
      soyisimError == "" &&
      telError == "" &&
      adresError == ""
    ) {
      let url = "https://www.alazmuhendislik.com/php-backend/register.php/";
      const formData = new FormData();
      formData.append("kullanicilar_isim", isim);
      formData.append("kullanicilar_soyisim", soyisim);
      formData.append("kullanicilar_mail", email);
      formData.append("kullanicilar_tel", tel);
      formData.append("sifre", password);
      formData.append("adres", adres);
      fetch(url, {
        method: "POST",
        body: formData,
      })
        //  .then(response => response.text())
        //  .then(result => console.log(result));
        .then((response) => response.json())
        .then((result) => {
          if (result == null) {
            alertify.error("Kayıt Başarısız Bilgilerinizi Kontrol Ediniz");
          } else if (result == "KayitliMail") {
            alertify.error("Daha Önce Kayıtlı Mail Adresi");
          } else {
            localStorage.setItem("user-info", result[0].kullanicilar_id);
            alertify.success("Kayıt Başarılı ", 2);
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
        });
    }
  }
  return (
    <div>
      <div className="col-sm-6 offset-sm-3">
        <h1 style={{color: "#ff6600", textAlign: "center"}}>Kişisel Bilgilerim </h1>
        <input
          type="text"
          placeholder="Ad"
          onChange={(event) => {
            setIsim(event.target.value);
          }}
          className="form-control"
        />
        {isimError == "" ? null : <a style={{ color: "red" }}>{isimError}</a>}
        <br />
        <input
          type="text"
          placeholder="Soyad"
          onChange={(event) => {
            setSoyisim(event.target.value);
          }}
          className="form-control"
        />
        {soyisimError == "" ? null : (
          <a style={{ color: "red" }}>{soyisimError}</a>
        )}
        <br />
        <input
          type="text"
          placeholder="Email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          className="form-control"
        />
        {mailError == "" ? null : <a style={{ color: "red" }}>{mailError}</a>}
        <br />
        <input
          type="text"
          placeholder="Telefon"
          value={tel}
          maxLength={13}
          onChange={(event) => {
            setTel(event.target.value);
          }}
          className="form-control"
        />
        {telError == "" ? null : <a style={{ color: "red" }}>{telError}</a>}
        <br />
        <input
          type="text"
          placeholder="Adres"
          onChange={(event) => {
            setAdres(event.target.value);
          }}
          className="form-control"
        />
        {adresError == "" ? null : <a style={{ color: "red" }}>{adresError}</a>}
        <br />
        <input
          type="password"
          placeholder="Şifre"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          className="form-control"
        />
        <br />
        <input
          type="password"
          placeholder="Şifre Doğrula"
          onChange={(event) => {
            setPasswordConfirm(event.target.value);
          }}
          className="form-control"
        />
        {sifreError == "" ? null : <a style={{ color: "red" }}>{sifreError}</a>}
        <br />
        <FormGroup check>
        <br />
          <Input type="checkbox" />
          {' '}
          <Label check>
            Mesafeli Satış Sözleşmesi ve Gizlilik ve Çerez Politikası'nı kabul ediyorum.
          </Label>
        </FormGroup>
        <br />

        <button onClick={register} className="btn btn-secondary">
          Üye Ol
        </button>
        <Link class="nav-link" style={{ color: "#ff6600" }} to="/login">Giriş Yap</Link>
      </div>
    </div>
  );
}
export default Register;
