import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class YanNavi extends Component {
  render() {
    if (window.innerWidth > 700) {
      return (
        <div>
          <Link to="/">
            <img
              src="https://www.alazmuhendislik.com/resimler/kargo.webp"
              width="100%"
              class="d-inline-block align-top"
              alt=""
            />
            <br />
          </Link>
          {/* <br />
           <iframe width="338" height="315"
            src="https://www.youtube.com/embed/kyMIocn7_jE"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true">  </iframe> */}
          <br />
          <Link to="/">
            <img
             src="https://www.alazmuhendislik.com/resimler/1.webp"
              width="100%"
              class="d-inline-block align-top"
              alt=""
            />
            <br />
          </Link>
          <br />

          {/* <iframe width="338" height="315"
            src="https://www.youtube.com/embed/pYcMwfLo9js"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true"
            poster="https://www.alazmuhendislik.com/resimler/vanacontrol1.png"
          ><br /> </iframe> */}

          <Link to="/">
            <img
              src="https://www.alazmuhendislik.com/resimler/2.webp"
              width="100%"
              class="d-inline-block align-top"
              alt=""

            />
            <br />
          </Link>
          <br />
          <Link to="/">
            <img
              src="https://www.alazmuhendislik.com/resimler/3.webp"
              width="100%"
              class="d-inline-block align-top"
              alt=""

            />
            <br />
          </Link>
          <br />

          <img
            src="https://www.alazmuhendislik.com/resimler/4.webp"
            width="100%"
            class="d-inline-block align-top"
            alt=""

          />
          <br /> 
          
          <br />
        </div>
      );
    }
    else if (window.innerWidth < 700) {
      return (
        <div></div>
      );
    }
  }
}
